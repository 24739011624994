//UTILITIES
import React from "react";
import { Outlet } from "react-router-dom";
//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import RoutingTabComponent from "../../../components/tab-component/RoutingTabComponent";

export function AccountProfile(props) {
  //VARIABLES

  const tabContent = [
    {
      id: 1,
      title: "Personal Info",
      path: `/account/personal-info`,
    },
    { id: 2, title: "Change Password", path: `/account/change-password` },
  ];
  return (
    <>
      <TitleComponent title="Account" classNames="page-title main-title" />
      <RoutingTabComponent withBorder tabContent={tabContent} />

      <div className="account-view-outlet">
        <Outlet />
      </div>
    </>
  );
}

export default AccountProfile;
