//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import * as yup from "yup";
import { AlertContext } from "../../../context/AlertContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//CSS
import "./Account.scss";
import { Card, CardContent } from "@mui/material";
import NoDataFound from "../../../components/response-messages/NoDataFound";
import {
  emailSchema,
  nameRequiredLimited,
  phoneNumberSchema,
} from "../../../core/validators/form-validators";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import {
  FormDropdownList,
  FormInput,
  FormPhoneInput,
} from "../../../components/form-component/FormComponent";
//API
import { updateUserDetail } from "../../../core/apis/user";
import { getAllCountries } from "../../../core/apis/address";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

const HandleSchema = yup.object( {
  email: emailSchema,
  first_name: nameRequiredLimited( true, 255 ),
  last_name: nameRequiredLimited( true, 255 ),
  country_slug: yup.object().nullable().required( "Field is required" ),
  phone_number: phoneNumberSchema,
} );

export function AccountInfo( props ) {
  let { user, updateUser } = useContext( UserContext );
  let { setAlert } = useContext( AlertContext );

  const { control, handleSubmit, reset } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );

  const [ loadingCountryData, setLoadingCountryData ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ countryData, setCountryData ] = useState( [] );

  const GetCountryData = () => {
    setLoadingCountryData( true );
    getAllCountries().then( ( response ) => {
      if ( response.data?.success ) {
        setCountryData( response.data.data ? response.data.data : [] );
      }

      setLoadingCountryData( false );
    } );
  };

  useEffect( () => {
    if ( user ) {
      reset( {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number,
        country_slug: user?.country,
      } );
    } else {
      setAlert( {
        text: "Failed to load information",
        error: true,
      } );
    }
  }, [ user ] );

  const handleSubmitForm = ( formData ) => {
    try {
      setSaveLoading( true );
      updateUserDetail( {
        ...formData,
        id: user?.id,
        country_slug: formData?.country_slug.slug,
      } ).then( ( res ) => {
        if ( res?.data?.success ) {
          setAlert( {
            text: res.data ? res.data.message : ErrorMessage,
            error: res.data?.success ? false : true,
          } );
          updateUser();
        }
        setSaveLoading( false );
      } );
    } catch ( e ) {
      setSaveLoading( false );
    }
  };

  useEffect( () => {
    GetCountryData();
  }, [] );

  return user ? (
    <Card>
      <CardContent>
        <form onSubmit={ handleSubmit( handleSubmitForm ) }>
          <div className="form-double-inputs-children">
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="first_name"
                  label="First Name"
                  placeholder={ "Enter first name" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="first_name"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="last_name"
                  label="Last Name"
                  placeholder={ "Enter last name" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="last_name"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  disabled
                  isAuth
                  name="email"
                  label="Email"
                  placeholder={ "Enter Email" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="email"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPhoneInput
                  isAuth
                  name="phone_number"
                  className="phone_number"
                  label="Phone Number"
                  placeholder={ "Enter phone number" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="phone_number"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormDropdownList
                  isAuth
                  data={ countryData || [] }
                  name="country_slug"
                  label="Country"
                  loading={ loadingCountryData }
                  placeholder={ "Enter country" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="country_slug"
              control={ control }
            />
          </div>
          <div className="form-footer-style display-footer-right">
            <div className="form-footer-elements">
              <PrimaryButton
                variant="outlined"
                text="Cancel"
                onClick={ () => reset() }
              />
              <PrimaryButton
                text={ "Save" }
                type="submit"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </div>
        </form>
      </CardContent>
    </Card>
  ) : (
    <NoDataFound variant="text" text="Failed to Load Information" />
  );
}
export default AccountInfo;
