import { createTheme } from "@mui/material/styles";
import palette from "./color.scss";
import sccsVariables from "./variables.scss";
import "./variables.scss";

export const appTheme = createTheme( {
  palette: {
    secondary: {
      main: "#9a9a9a",
    },
    primary: {
      main: palette.primaryColor,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          width: "100%",
          fontSize: "18px",
          height: "35px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflowX: "hidden",

          padding: "0px 25px 0px 25px",
          textTransform: "none",
          boxShadow: "unset",
          "@media (max-width:1501px)": { fontSize: "18px" },
          "@media (max-width:1200px)": { fontSize: "13px" },
          "@media (max-width:791px)": { fontSize: "12px" },
          "@media (max-width:500px)": { fontSize: "12px" },
          "@media (max-width:400px)": { padding: "0px 20px 0px 20px" },

          "&:focus-visible": {
            outline: "unset",
          },
          "&:focus": {
            outline: "unset",
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: { marginBottom: "none" },
        label: {
          marginBottom: "none",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginBottom: "none",
          padding: '0px 9px 0px 9px', // Adjust padding as needed for the button inside the checkbox

        },
        label: {
          marginBottom: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: sccsVariables.tableShadow,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus-visible": {
            outline: "unset",
          },
          "&:hover": { background: "none" },
          "&:focus": {
            outline: "unset",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.primaryColor,
          fontSize: "14px",
        },
        arrow: {
          "&:before": {
            border: `1px solid ${ palette.primaryColor }`,
          },
          color: palette.primaryColor,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            // background: palette.whiteColor,
            "&:hover fieldset": {
              border: `1px solid  ${ palette.primaryColor }`,
            },
          },
          fieldset: {
            "& legend": {
              width: " unset",
            },

            borderRadius: "8px",
            border: `1px solid  ${ palette.primaryColor }`,
          },

          input: {
            // backgroundColor: palette.whiteColor,
            borderRadius: "15px",
            "&::placeholder": {
              color: palette.secondary,
            },
            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.primaryColor,
          fontWeight: "400",
          textAlign: "left",
          marginBottom: "10px",
          lineHeight: "16px",
          letterSpacing: "0em",
          fontSize: "14px",
          "@media (max-width:1501px)": { fontSize: "14px" },
          "@media (max-width:500px)": { fontSize: "14px" },
          "@media (max-width:860px)": { fontSize: "12px" },
          "@media (max-width:791px)": { fontSize: "12px" },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
          margin: "0px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textOverflow: "ellipsis",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#00172d",
          borderRadius: "5px",
          border: `0px solid #ffff`,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            "&:hover": {
              backgroundColor: palette.primaryColor,
              background: palette.primaryColor,
            },
          },
          "&:hover": {
            backgroundColor: palette.primaryColor,
            background: palette.primaryColor,
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          textOverflow: "ellispsis",
          overflowX: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          background: palette.mediumGreyColor,
          color: palette.primaryColor,
          fontWeight: 700,
          fontSize: "18px",
          "@media (max-width:1000px)": { fontSize: "18px" },
          "@media (min-width:1800px)": { fontSize: "20px" },
          "@media (min-width:2000px)": { fontSize: "25px" },
        },
        body: {
          padding: "10px 16px",
          lineHeight: "16px",
          fontWeight: "700",
          fontSize: "16px",
          maxWidth: "300px",
          border: "unset",

          "@media (max-width:1000px)": { fontSize: "16px" },
          "@media (min-width:1800px)": { fontSize: "16px" },
          "@media (min-width:2000px)": { fontSize: "18px" },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: palette.whiteColor,
          borderRadius: "5px",
        },
      },
    },
    MuiTabList: {
      styleOverrides: {
        root: {
          backgroundColor: palette.whiteColor,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "13px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "18px",

          fontWeight: 400,
          textTransform: "none",
        },
      },
    },
  },
} );
export default appTheme;
