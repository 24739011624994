//UTILITIES
import React, { useContext, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";

import { Card, CardContent } from "@mui/material";

import { PrimaryButton } from "../../../components/buttons/Buttons";
import { FormPassword } from "../../../components/form-component/FormComponent";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword } from "../../../core/apis/auth";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

const HandleSchema = yup.object( {
  current_password: yup.string().required( "Field is required" ),
  password: yup
    .string( "Invalid Field" )
    .min( 6, "Field should be of minimum 6 characters length" )
    .required( "Field is required" ),
  password_confirmation: yup
    .string( "Invalid Field" )
    .oneOf( [ yup.ref( "password" ), null ], "Passwords must match" )
    .required( "Field is required" ),
} );

export function AccountPassword( props ) {
  //VARIABLES
  const { setAlert } = useContext( AlertContext );

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );

  const [ saveLoading, setSaveLoading ] = useState( false );

  const handleSubmitForm = ( data ) => {
    try {
      setSaveLoading( true );
      changePassword( data ).then( ( res ) => {
        if ( res?.data?.success ) {
          reset( {
            current_password: "",
            password: "",
            password_confirmation: "",
          } );
        }
        setAlert( {
          text: res.data ? res.data.message : ErrorMessage,
          error: res.data?.success ? false : true,
        } );
        setSaveLoading( false );
      } );
    } catch ( e ) {
      setSaveLoading( false );
    }
  };

  return (
    <div className="personal-form">
      <Card>
        <CardContent>
          <form onSubmit={ handleSubmit( handleSubmitForm ) } className="account-password">
            <div className="inputs-section">
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPassword
                    label="Current password"
                    name="current_password"
                    placeholder="Enter current password"
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="current_password"
                control={ control }
              />

              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPassword
                    label="New Password"
                    name="password"
                    placeholder="Enter new password"
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="password"
                control={ control }
              />

              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPassword
                    label="Confirm password"
                    name="password_confirmation"
                    placeholder="Enter password"
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="password_confirmation"
                control={ control }
              /></div>

            <div className="form-footer-style display-footer-right">
              <div className="form-footer-elements">
                <PrimaryButton
                  variant="outlined"
                  text="Cancel"
                  onClick={ () =>
                    reset( {
                      password_confirmation: "",
                      password: "",
                      current_password: "",
                    } )
                  }
                />
                <PrimaryButton
                  text={ "Save" }
                  type="submit"
                  loading={ saveLoading }
                  disabled={ saveLoading ? true : false }
                />
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default AccountPassword;
