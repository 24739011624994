import { api } from "./main";

export const getAllPlans = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";

  return api
    .get( `admin/v1/get-all-plans${ page }`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
export const updatePlanOrder = async ( payload ) => {
  return await api
    .patch( "admin/v1/update-plans-order", payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const updatePlan = async ( payload ) => {
  return await api
    .put( `admin/v1/update-plan/${ payload?.slug }`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const deletePlan = async ( payload ) => {
  return await api
    .delete( `admin/v1/delete-plan/${ payload?.slug }` )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const createPlan = async ( payload ) => {
  return await api
    .post( "admin/v1/create-plan", payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const getPlanBySlug = async ( payload ) => {
  return await api
    .get( `admin/v1/get-plan-by-slug/${ payload }`, {
      parmas: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const getAllCurrencies = async ( payload ) => {
  return await api
    .get( `admin/v1/currencies`, {
      parmas: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
