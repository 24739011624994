import { api } from "./main";

export const getAllInvoices = (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";

  return api
    .get(`admin/v1/invoices${page}`, { params: { ...payload, portal_id: 1 } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getInvoiceById = (payload) => {
  return api
    .get(`admin/v1/invoice/${payload}`, { params: { ...payload } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const exportInvoices = (payload) => {
  return api
    .get(`admin/v1/export-invoices`, {
      params: { ...payload },
      responseType: "blob",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
