//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
//COMPONENY
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
//API
import { dataPerPage } from "../../../core/variables/CoreVariables";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import { TableCell } from "@mui/material";
import RowComponent from "../../../components/table-component/RowComponent";
import { AlertContext } from "../../../context/AlertContext";
import { exportInvoices, getAllInvoices } from "../../../core/apis/invoices";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import { groupParamsByKey } from "../../../core/functions/useDebounce";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function Invoices( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const { min_amount, max_amount, per_page, page, name, start_date, end_date } = groupParamsByKey( searchParams ) || {};
  const { setAlert } = useContext( AlertContext );
  const [ loading, setLoading ] = useState( false );
  const [ total, setTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );
  const [ totalPage, setTotalPage ] = useState( 0 );

  const tableHeaders = [
    {
      name: "Invoice",
    },

    {
      name: "Date",
    },
    {
      name: "Amount",
    },
    {
      name: "Discount",
    },
    {
      name: "Net",
    },

    {
      name: "Subscriber",
    },
    {
      name: "Email",
    },
    {
      name: "Coupon",
    },
  ];
  //Functions
  const GetData = () => {
    setLoading( true );
    getAllInvoices( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      search: searchParams.get( 'name' ) || "",
      min_amount: searchParams.get( "min_amount" ) || null,
      max_amount: searchParams.get( "max_amount" ) || null,
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) || "" },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" },
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setTotal( response.data.data?.invoices?.total );
        setTotalPage( response.data.data?.invoices?.last_page );
        setData(
          response.data.data?.invoices ? response.data.data.invoices?.data : []
        );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };


  const handleExportFunctionality = () => {
    if ( searchParams.get( 'start_date' ) && searchParams.get( 'end_date' ) ) {
      exportInvoices( {
        start_date: searchParams.get( 'start_date' ),
        end_date: searchParams.get( 'end_date' ),
      } ).then( ( response ) => {
        if ( response?.status === 200 ) {
          const url = window.URL.createObjectURL( new Blob( [ response?.data ] ) );
          let filename = `invoices.xlsx`;
          const link = document.createElement( "a" );
          link.href = url;
          link.setAttribute( "download", filename );
          // Append to html link element page
          document.body.appendChild( link );
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild( link );
        } else {
          setAlert( {
            text: "Failed to download",
            error: true,
          } );
        }
      } );
    } else {
      setAlert( {
        text: "Please select a date range",
        error: true,
      } );
    }
  };

  useEffect( () => {
    GetData();
  }, [ min_amount, max_amount, name, per_page, page, start_date, end_date ] );
  return (
    <>
      <TitleComponent title={ `Invoices` } classNames="page-title main-title" />
      <TitleComponent
        title={ `Total Invoices : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        filterable
        exportFunctionality={ total > 0 && handleExportFunctionality }
        placeholder={ "Search by invoice number or subscriber" }
        filterByDateRange
        filterByAmountRange
        search
        type="receipts"
      />
      <div className="margin-up-20">
        <TableComponent
          headers={ tableHeaders }
          loading={ loading }
          data={ data }
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent
              key={ element?.id }
              row={ element }
              handleRowClick={ () => navigate( `/invoices/detail/${ element?.id }` ) }
            >
              <TableCell>{ element.invoice_number }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element.created_at } date />
              </TableCell>
              <TableCell>
                <div className="invoice-amount-display">
                  <div>{ element.subtotal }</div>
                  <div>{ element?.currency?.symbol }</div>
                </div>
              </TableCell>
              <TableCell>
                <div className="invoice-amount-display">
                  <div> { element.discounted_value }</div>
                  <div>{ element?.currency?.symbol }</div>
                </div>



              </TableCell>
              <TableCell>
                <div className="invoice-amount-display">
                  <div>{ element.total }</div>
                  <div>{ element?.currency?.symbol }</div>
                </div>
              </TableCell>
              <TableCell className='long-tablecell-description'>
                { `${ element.user_first_name ? element.user_first_name : "" } ${ element?.user_last_name ? element?.user_last_name : ""
                  }` }
              </TableCell>
              <TableCell>{ element.user_email }</TableCell>
              <TableCell className='long-tablecell-description'>
                { element.coupon?.code ? element.coupon?.code : "-" }
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection

        data={ data }

        total={ total }
        totalPage={ totalPage }

      />
    </>
  );
}

export default Invoices;
