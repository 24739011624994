import React from "react";
import PropTypes from "prop-types";
//MUI
import { TableSortLabel, TableRow, TableCell, TableHead } from "@mui/material";

import { IconsButton } from "../buttons/Buttons";
import { PlusIcon } from "../../assets/svg/SVG";
import palette from "../../assets/theme/color.scss";

export function TableCustomHeader(props) {
  const { onAdd, actions } = props;

  const handleRequestSort = (e, item) => {
    if (props.sorted) {
      props.requestSort(item);
    }
  };
  return (
    <TableHead>
      <TableRow>
        {props.headers.map((item, index) => (
          <TableCell key={index} align={item?.align ? item?.align : "left"}>
            {item.sorted === true ? (
              <TableSortLabel
                direction={props.orderBy}
                active={props.sortedBy === item.value}
                onClick={(e) => handleRequestSort(e, item?.sorted)}
              >
                {item.name}
              </TableSortLabel>
            ) : (
              item.name
            )}
          </TableCell>
        ))}

        {actions && (
          <TableCell align="right">
            {onAdd && (
              <IconsButton
                icon={<PlusIcon fill={palette.primaryColor} />}
                onClick={() => onAdd()}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export default TableCustomHeader;
TableCustomHeader.defaultProps = {
  actions: true,
};
TableCustomHeader.propTypes = {
  actions: PropTypes.bool,
  onAdd: PropTypes.func,
};
