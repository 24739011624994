//UTILITIES
import { useContext, useRef, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SideBarContext } from "../context/SideBarContext";
//COMPONENT
import NavigationBar from "./NavigationBar";

export function LayoutWithNavbar( props ) {
  const { authentication } = props;
  const { sideBar, setSideBar } = useContext( SideBarContext );

  const outletRef = useRef( null );

  //to close the sidebar if we click on the children
  const handleClick = ( event ) => {
    if ( event.type === "click" ) {
      // Check if click occurred inside sidebar
      if ( outletRef.current && outletRef.current.contains( event.target ) ) {
        // 👇️ call submit function here
        setSideBar( false );
      }
    }
  };

  useEffect( () => {
    // Add event listener to sidebar element
    outletRef?.current?.addEventListener( "click", handleClick );

    // Remove event listener on component unmount
    return () => {
      outletRef?.current?.removeEventListener( "click", handleClick );
    };
  }, [] );

  return (
    <>
      <NavigationBar authentication={ authentication } />
      <div
        ref={ outletRef }
        className={ `navigations-bar-outlet ${ !authentication
          ? `portal-component ${ sideBar ? "sideOpen" : "sideClose" }`
          : "authentication-component"
          }` }
      >

        <Outlet />

      </div>
    </>
  );
}
export default LayoutWithNavbar;
