import PropTypes from "prop-types";
//LAYOUT

import palette from "../../assets/theme/color.scss";
import { Button, CircularProgress, IconButton } from "@mui/material";

//CSS
import "./Button.scss";

export const LinkButton = (props) => {
  const { className, startIcon, onClick, text, loading, color } = props;

  return (
    <Button
      variant="text"
      startIcon={startIcon ? startIcon : null}
      style={{
        color:
          color === "primary"
            ? palette.primaryColor
            : color === "secondary"
            ? palette.secondaryColor
            : "black",
      }}
      className={`link-button ${className}`}
      onClick={onClick}
    >
      {loading ? (
        <CircularProgress
          size={"1rem"}
          style={{ color: palette.primaryColor }}
        />
      ) : (
        <>{text}</>
      )}
    </Button>
  );
};

export const IconsButton = (props) => {
  const { className, onClick, icon } = props;

  return (
    <IconButton
      color="primary"
      className={className ? className : ""}
      component="span"
      onClick={() => onClick()}
    >
      {icon}
    </IconButton>
  );
};

export const IconsTextButton = (props) => {
  const { className, onClick, endIcon, text, textColor } = props;
  return (
    <Button
      variant="text"
      endIcon={endIcon ? endIcon : null}
      color="secondary"
      style={{
        color: textColor === "secondary" ? palette.inputColor : "black",
      }}
      className={`link-button ${className ? className : ""}`}
      component="span"
      onClick={() => onClick()}
    >
      {text}
    </Button>
  );
};

export const PrimaryButton = (props) => {
  const {
    disabled,
    type,
    variant,
    onClick,
    className,
    icon,
    text,
    loading,
    startIcon,
  } = props;

  return (
    <Button
      variant={variant}
      type={type ? type : "button"}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon ? startIcon : null}
      className={icon ? `icon-button-style ${className}` : className}
      color="primary"
    >
      {loading ? (
        <CircularProgress size={"1rem"} style={{ color: palette.whiteColor }} />
      ) : (
        <>
          {icon && icon}
          {text ? text : ""}
        </>
      )}
    </Button>
  );
};

PrimaryButton.defaultProps = {
  variant: "contained",
};
PrimaryButton.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export const SecondaryButton = (props) => {
  const { type, onClick, className, startIcon, icon, text } = props;
  //ICON IF THE BUTTON ONLY CONTAIN ICON
  //START ICON IF THE BUTTON CONTAIN ICON IN THE BEGGINING WITH TEXT (MUI CONFIGURATION)
  return (
    <Button
      variant="contained"
      onClick={onClick}
      color="secondary"
      className={className}
      startIcon={startIcon ? startIcon : null}
      type={type ? type : "button"}
    >
      {icon && icon}

      {text ? text : ""}
    </Button>
  );
};
