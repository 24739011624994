//RPIVATE ROUTES FOR EACH ROUTE
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const PrivateRoutes = ( props ) => {
  const { shouldbeloggedIn, children } = props;
  const { session } = useContext( UserContext )

  if ( shouldbeloggedIn === true ) {
    return session ? children : <Navigate to="/" replace />;
  } else {
    return !session ? children : <Navigate to="/subscribers" replace />;
  }


};

export default PrivateRoutes;
