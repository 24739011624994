import {
  AccountIcon,
  CouponsSVG,
  DashboardIcon,
  ImageIcon,
  InvoicesIcon,
  ReceiptsSVG,
  SessionIcon,
  SubscriptionsIcon,
} from "../../assets/svg/SideBarSVG";

import ClearIcon from "@material-ui/icons/Clear";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import palette from "../../assets/theme/color.scss";

export const SideBarItems = [
  {
    id: "dashboard",
    text: "Dashboard",
    route: "/dashboard",
    icon: <DashboardIcon />,
    role: "show-dashboard",
  },
  {
    id: "sessions",
    text: "Sessions",
    route: "/sessions",
    icon: <SessionIcon />,
  },
  {
    id: "images",
    text: "Images",
    icon: <ImageIcon />,
    children: [
      {
        id: "background-image",
        text: "Background",
        route: "/background-images",
        is_children: true,
      },
      {
        id: "board-image",
        text: "Board",
        route: "/board-images",
        is_children: true,
      },
    ],
  },
  {
    id: "subscribers",
    text: "Subscribers",
    route: "/subscribers",
    icon: <PeopleAltIcon style={{ color: palette.whiteColor }} />,
  },

  {
    id: "subscriptions",
    text: "Subscriptions",
    route: "/subscriptions",
    icon: <SubscriptionsIcon />,
  },

  {
    id: "invoices",
    text: "Invoices",
    route: "/invoices",
    role: "show-receipts",
    icon: <ReceiptsSVG />,
  },
  {
    id: "coupons",
    text: "Coupons",
    route: "/coupons",
    icon: <CouponsSVG />,
  },

  {
    id: "deleted-sessions",
    text: "Deleted Sessions",
    route: "/deleted-sessions",
    icon: <ClearIcon style={{ color: "#ffff" }} />,
  },

  {
    id: "account",
    text: "Account",
    route: "/account",
    icon: <AccountIcon />,
  },
];
