//UTILITIES
import moment from "moment";
import PropTypes from "prop-types";
import "moment-timezone";
export const DisplayDateTime = (props) => {
  const { date, data } = props;

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //to get the timezone of the user<
  const utcMoment = moment.utc(data, 'YYYY-MM-DD HH:mm:ss');
  const beirutMoment = utcMoment.tz(userTimezone);

  return data
    ? date
      ? beirutMoment.format("DD-MM-YYYY")
      : beirutMoment.format("DD-MM-YYYY HH:mm")
    : "-";
};

DisplayDateTime.defaultProps = {
  date: false,
};
DisplayDateTime.propTypes = {
  date: PropTypes.bool,
  data: PropTypes.string,
};
