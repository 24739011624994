//REACT
import React from "react";
import PropTypes from "prop-types";

//CSS
import "./ResponseMessages.scss";
import { NoDataFoundSVG } from "../../assets/svg/SVG";

function NoDataFound(props) {
  const { normal, variant, text } = props;

  return (
    <div className={`no-data-found ${normal ? "" : "absolute-position"}`}>
      {variant === "text" ? (
        text
      ) : (
        <>
          <NoDataFoundSVG />
          <div>No Data</div>
        </>
      )}
    </div>
  );
}

export default NoDataFound;
NoDataFound.defaultProps = {
  normal: true,
  variant: "component",
  text: "No Data Found",
};
NoDataFound.propTypes = {
  normal: PropTypes.bool,
  variant: PropTypes.string,
  text: PropTypes.string,
};
