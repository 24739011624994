//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCookies } from "react-cookie";
//COMPONENT
import { Card, CardContent } from "@mui/material";
import TitleComponent from "../../../components/title-component/TitleComponent";
import { UserContext } from "../../../context/UserContext";
import { AlertContext } from "../../../context/AlertContext";
import {
  FormPassword,
  FormInput,
} from "../../../components/form-component/FormComponent";
import { LinkButton, PrimaryButton } from "../../../components/buttons/Buttons";

//API
import { adminLogin } from "../../../core/apis/auth";

//CSS
import "./Login.scss";
import { ErrorMessage } from "../../../core/variables/MessageVariables";




const HandleSchema = yup.object( {
  email: yup.string().email().required( "Field is required" ),
  password: yup.string().required( "Field is required" ),
} );

export function Login( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  const { updateUser } = useContext( UserContext );
  const [ cookies, setCookie ] = useCookies( [ "session" ] );
  const [ loading, setLoading ] = useState( false );

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    getValues,

    formState: { errors },
  } = useForm( {
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleForgotPassword = () => {
    navigate( "/reset" );
  };

  const handleSubmitForm = ( formData ) => {
    setLoading( true );

    adminLogin( formData ).then( async ( response ) => {
      if ( response.data?.success ) {
        setCookie( "session", response?.data?.success, { path: "/" } );
        await updateUser();
        navigate( "/subscribers" );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
      }
      setLoading( false );
    } );
  };

  const keyDownHandler = ( event ) => {
    if ( event.key === "Enter" ) {
      // 👇️ call submit function here
      event.preventDefault();
      handleSubmitForm( getValues() );
    }
  };

  useEffect( () => {
    document.addEventListener( "keydown", keyDownHandler );
    return () => {
      document.removeEventListener( "keydown", keyDownHandler );
    };
  }, [ getValues() ] );

  return (
    <div className="page-container">
      <Card className="login-form-container">
        <CardContent className="content">
          <TitleComponent title="Log In" classNames="form-title main-title" />
          <form onSubmit={ handleSubmit( handleSubmitForm ) } className="form-container">

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  label="Email"
                  name="email"
                  placeholder={ "Enter email" }
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="email"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPassword
                  name="password"
                  isAuth
                  label="Password"
                  placeholder={ "Enter password" }
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="password"
              control={ control }
            />

            <div className="forgot-password-space">
              <LinkButton
                color={ "primary" }
                className={ "forgot-button" }
                type="text-button"
                text={ "Forgot password?" }
                onClick={ handleForgotPassword }
              />

            </div>

            <div className="flex-column-wrap login-action">
              <PrimaryButton
                type="submit"
                text="Submit "
                className="login-button"
                loading={ loading }
                disabled={ loading ? true : false }
              />
            </div>

          </form>
        </CardContent>
      </Card>

    </div>
  );
}

export default Login;
