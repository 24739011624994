import { api } from "./main";

export const getAllCountries = async ( payload ) => {
  return await api
    .get( "v1/get-all-countries", { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
