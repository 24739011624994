import { api } from "./main";

export const getAllPlanItems = (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";

  return api
    .get(`admin/v1/get-all-plan-items${page}`, { params: { ...payload } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
