//UTILITIES
import React, { useState, useEffect, useContext } from "react";

//COMPONENT
import {
  dataPerPage,
  imagePerPage,
} from "../../../core/variables/CoreVariables";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
import TitleComponent from "../../../components/title-component/TitleComponent";
import ImageComponent from "./ImageComponent";
import NoDataFound from "../../../components/response-messages/NoDataFound";
import { ImageSkeletonComponent } from "../../../components/loader-component/LoaderComponent";
//DRAG FUNCTIONALITY
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

//API
import ImageHandle from "./ImageHandle";
//CSSS
import "./ImageManagement.scss";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import { AlertContext } from "../../../context/AlertContext";
import {
  deleteImage,
  getAllImages,
  updateImageOrder,
} from "../../../core/apis/image";
import { useParams, useSearchParams } from "react-router-dom";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import LoadMorePagination from "../../../components/table-component/LoadMorePagination";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function CategoryImages( props ) {
  //VARIABLES
  const { background } = props;
  // const { slug } = useParams(); //we changed the ui, no longer categorization
  const slug = background ? "background" : "board";
  const { setAlert } = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { is_admin } = groupParamsByKey( searchParams ) || {};
  const [ canDrag, setCanDrag ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ canEdit, setCanEdit ] = useState( true );
  const [ loadMore, setLoadMore ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ loadingDrag, setLoadingDrag ] = useState( false );
  const [ openAddImage, setOpenAddImage ] = useState( false );
  const [ openConfirmDelete, setOpenConfirmDelete ] = useState( false );
  const [ imageToEdit, setImageToEdit ] = useState( null );
  const [ total, setTotal ] = useState( 0 );

  const [ data, setData ] = useState( [] );

  const isBetween = ( index ) => {

  }
  //Functions
  // Handle drag movement and setting array values
  // Handle drag movement and setting array values
  const handleDragEnd = ( { active, over } ) => {
    if ( active.id !== over.id ) {
      const dragId = data.find( ( item ) => item.id === active.id );
      const dropId = data.find( ( item ) => item.id === over.id );

      if ( dragId && dropId ) {
        const oldIndex = data.indexOf( dragId );
        const newIndex = data.indexOf( dropId );
        // updating data new indexes without calling the API
        // Calculate the new sort_order for the moved item (C)
        const newSortOrder = dropId.sort_order;
        const oldSortOrder = dragId.sort_order;


        // Update data with new indexes and sort_order
        // cecilio doesn't want to refetch data that's why we need to update them from frontend
        setData( ( items ) => {
          const updatedItems = arrayMove( items, oldIndex, newIndex ).map(
            ( item, index ) => ( {
              ...item,
              sort_order:
                index === newIndex
                  ? newSortOrder
                  : oldSortOrder < newSortOrder && item.sort_order > oldSortOrder && item.sort_order <= newSortOrder
                    ? item.sort_order - 1
                    : oldSortOrder > newSortOrder && item.sort_order <= oldSortOrder && item.sort_order > newSortOrder
                      ? item.sort_order + 1
                      : item.sort_order,
            } )

            //explanation:
            //if we have A , B ,C ,D 
            //if A is moved to D => A should have the sort order of D ,  all between A and D including D should have sort order +1
            //if D is moved to A => D should have the sort order of A , all between D and A including A should have sort order -1
          );

          return updatedItems;
        } );


        //calling api to update destination order key property
        handleSaveDrag( {
          image_id: dragId?.id,
          destination_order: newSortOrder,
        } );
      }
    }
  };

  const handleSaveDrag = ( payload ) => {
    setLoadingDrag( true );

    updateImageOrder( {
      ...payload,
      category_slug: slug,
    } ).then( ( res ) => {
      setAlert( {
        text: res.data ? res.data.message : ErrorMessage,
        error: res?.data?.success ? false : true,
      } );

      setLoadingDrag( false );
    } );
  };

  const handleSetDrag = () => {
    setCanDrag( true );
    setCanEdit( false );
  };

  //GET PAGINATED DATA (IF WE CLICK VIEW MORE TO COMMENT )
  const GetPaginatedData = ( new_page ) => {
    setLoadMore( true );
    let old = [ ...data ];
    getAllImages( {
      category_slug: slug,
      per_page: imagePerPage,
      page: new_page,
    } ).then( ( response ) => {

      if ( response.data?.success ) {
        setPage( response.data.data?.current_page );
        if ( response.data.data ) {
          response.data.data.data?.map( ( item ) => old.push( item ) );
        }

        setData( old );
        setTotalPage( response.data.data?.last_page );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response?.data?.success ? false : true,
        } );
      }
      setLoadMore( false );
    } );
  };

  const GetData = () => {

    setLoading( true );
    getAllImages( {
      is_admin: searchParams.get( 'is_admin' ) || null,
      category_slug: slug,
      page: 1,
      per_page: imagePerPage,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setPage( 1 );
        setTotal( response.data.data ? response?.data?.data?.total : 0 );
        setData( response.data.data ? response.data.data?.data : [] );
        setTotalPage( response.data.data ? response?.data?.data?.last_page : 0 );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
      }

      setLoading( false );
    } );
  };

  useEffect( () => {
    GetData();
  }, [ is_admin, slug ] );





  const handleDelete = ( element ) => {
    setImageToEdit( element );
    setOpenConfirmDelete( true );
  };

  const handleDeleteResponse = ( payload ) => {

    deleteImage( payload?.id ).then( ( res ) => {

      if ( res?.data?.success ) {
        GetData();
      }
      setAlert( {
        text: res.data ? res.data?.message : ErrorMessage,
        error: res.data?.success ? false : true,
      } );
      setOpenConfirmDelete( false );
      return res;
    } );

  };
  const sensors = useSensors( useSensor( PointerSensor ) );

  const handleResetFilter = () => {
    let newFilters = {
      is_admin: null,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };
  const handleImageHandleResponse = ( message ) => {
    setOpenAddImage( false );
    handleResetFilter();
    GetData();
  };

  const handleLoadChange = ( type ) => {
    if ( type === "more" ) {
      setPage( page + 1 );
      GetPaginatedData( page + 1 );
    } else {
      setPage( page - 1 );
      GetPaginatedData( page - 1 );
    }
  };

  const handleSetDelete = () => {
    setCanDrag( false );
    setCanEdit( true );
  };
  const handleCloseHandleImage = () => {
    setOpenAddImage( false );
  };
  const handleAddImage = () => {
    setOpenAddImage( true );
  };

  return (
    <div className="image-management-container">
      <TitleComponent
        title={ `${ slug.charAt( 0 ).toUpperCase() + slug.slice( 1 ) } Images` }
        classNames="page-title main-title"
      />
      <div className="total-data-counts">Total images : { total }</div>
      <TableUpsideSection
        filterByDoneBy
        search={ false }
        addButtonTitle={ "Upload image" }
        addButton={ handleAddImage }
        handleDrag={ !canDrag && handleSetDrag }
        handleDelete={ !canEdit && handleSetDelete }
        dragChecked={ canDrag }
        loadingDrag={ loadingDrag }
        type="board-images"
      />
      <div className="margin-up-20">
        { !loading ? (
          data?.length !== 0 ? (
            <>
              <DndContext
                sensors={ sensors }
                collisionDetection={ closestCenter }
                onDragEnd={ handleDragEnd }
              >
                <div className="image-body-container">
                  <SortableContext
                    items={ data || [] }
                    strategy={ rectSortingStrategy }
                  >
                    { data?.map( ( item, index ) => {
                      return (
                        <ImageComponent
                          { ...props }
                          key={ item.id }
                          data={ item }
                          index={ index }
                          handle={ true }
                          loadingAvailable={ loadingDrag }
                          canDrag={ canDrag }
                          canEdit={ canEdit }
                          handleDelete={ handleDelete }
                        />
                      );
                    } ) }
                  </SortableContext>
                </div>
              </DndContext>

              <LoadMorePagination
                page={ page }
                loading={ loadMore }
                pageTotal={ totalPage }
                data={ data }
                dataLeft={ data ? total - data.length : 0 }
                handleLoadChange={ handleLoadChange }
              />
            </>
          ) : (
            <NoDataFound />
          )
        ) : (
          <ImageSkeletonComponent count={ 10 } background={ background } />
        ) }
      </div>

      { openAddImage && (
        <ImageHandle
          background={ background }
          category_slug={ slug }
          onClose={ handleCloseHandleImage }
          handleResponse={ handleImageHandleResponse }
        />
      ) }
      { openConfirmDelete && (
        <NoticeConfirmation
          onClose={ () => setOpenConfirmDelete( false ) }
          onSuccess={ handleDeleteResponse }
          data={ imageToEdit }
        />
      ) }
    </div>
  );
}

export default CategoryImages;
