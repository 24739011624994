//UTILITIES
import React from "react";
import PropTypes from "prop-types";
//COMPONENT
import { TableCell, TableRow, IconButton } from "@mui/material";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CSS } from "@dnd-kit/utilities";
import { CopyIcon, DeleteSVG, DragIcon, EditIcon } from "../../assets/svg/SVG";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import variablesScss from "../../../src/assets/theme/variables.scss";
import palette from "../../../src/assets/theme/color.scss";

import { useSortable } from "@dnd-kit/sortable";
//API

//CSS
import "./TableComponent.scss";

export function RowComponent(props) {
  const {
    row,
    handlePlay,
    children,
    handleDelete,
    handleCopy,
    handleRowClick,
    actions,
    handleView,
    handleEdit,
    canDrag,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: row?.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: canDrag ? "grab" : "",
  };

  const all_listeners = canDrag ? { ...listeners } : null;
  return (
    <>
      <TableRow
        {...attributes}
        {...all_listeners}
        ref={setNodeRef}
        style={{
          ...style,
          background:
            canDrag && isDragging ? palette.primaryColorDark20 : "unset",
          boxShadow:
            canDrag && isDragging ? variablesScss.shadowElement : "unset",
        }}
        className={handleRowClick ? "cursor-pointer tableRow" : "tableRow"}
        hover={handleRowClick ? true : false}
        onClick={handleRowClick}
        key={row?.id}
      >
        {children}
        {actions && (
          <TableCell align="right">
            {handleView && (
              <IconButton
                color="primary"
                className="button-span"
                component="span"
                onClick={() => {
                  handleView(row);
                }}
              >
                <VisibilityIcon
                  style={{
                    color: palette.primaryColorDark,
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            )}

            {canDrag && (
              <IconButton
                color="primary"
                className="button-span"
                component="span"
              >
                <DragIcon />
              </IconButton>
            )}

            {handlePlay && (
              <IconButton
                color="primary"
                className="button-span"
                component="span"
                onClick={() => handlePlay(row)}
              >
                <PlayArrowIcon />
              </IconButton>
            )}
            {handleEdit && (
              <IconButton
                color="primary"
                className="button-span"
                component="span"
                onClick={(e) => {
                  e?.stopPropagation();
                  handleEdit(row);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
            {handleDelete && (
              <IconButton
                color="primary"
                className="button-span"
                component="span"
                onClick={(e) => {
                  e?.stopPropagation();
                  handleDelete(row);
                }}
              >
                <DeleteSVG fill={"#C23131"} />
              </IconButton>
            )}

            {handleCopy && (
              <IconButton
                color="primary"
                className="button-span"
                component="span"
                onClick={() => handleCopy(row)}
              >
                <CopyIcon fill={"#31c2aa"} />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
export default RowComponent;

RowComponent.defaultProps = {
  actions: true,
};
RowComponent.propTypes = {
  actions: PropTypes.bool,
  handleDelete: PropTypes.func,
  handlePlay: PropTypes.func,
  handleView: PropTypes.func,
  handleCopy: PropTypes.func,
  handleEdit: PropTypes.func,
};
