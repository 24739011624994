//UTILITIES
import React from "react";
//CSS
import "./ResponsePages.scss";
export function NoPageFound(props) {
  return (
    <div className="page-not-found">
      <div>404</div>
      <div>Sorry, we were unable to find that page</div>
    </div>
  );
}

export default NoPageFound;
