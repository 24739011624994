//UTILITIES
import React, { useContext, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import { Dialog, DialogContent } from "@mui/material";

import { PrimaryButton } from "../../../components/buttons/Buttons";
import DialogCustomTitle from "../../../components/dialog-component/DialogCustomTitle";
import ResponseMessage from "../../../components/response-messages/ResponseMessage";
import { FormCalendarInput } from "../../../components/form-component/FormComponent";
//CSS

import { extendUserTrial } from "../../../core/apis/user";
import { AlertContext } from "../../../context/AlertContext";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

const HandleSchema = yup.object( {
  extend_date: yup.string().required( "Field is required" ),
} );

export function UsersExtendTrial( props ) {
  //VARIABLES
  const { onClose, data, handleResponse } = props;
  const { setAlert } = useContext( AlertContext );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ message, setMessage ] = useState( "" );
  const today = new Date();
  const yesterday = new Date( today );
  //INITIATE USE FORM
  const { control, handleSubmit } = useForm( {
    defaultValues: {
      extend_date: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    extendUserTrial( {
      plan_subscription_id: data?.latest_subscription?.id,
      extend_date: formData?.extend_date
        ? moment( formData?.extend_date ).format( "YYYY-MM-DD" )
        : null,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setAlert( {
          text: response?.data ? response?.data?.message : response?.message,
          error: response?.data?.success ? false : true,
        } );
        handleResponse();
        onClose();
      } else {
        setMessage( response.data ? response.data.message : ErrorMessage );
      }
      setSaveLoading( false );
    } );
  };

  return (
    <>
      <Dialog disablebackdropclick="true" fullWidth open={ true }>
        <DialogCustomTitle
          title={ "Update Trial Period" }
          onClose={ () => onClose() }
        />

        <DialogContent>
          <form onSubmit={ handleSubmit( handleSubmitForm ) }>
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormCalendarInput
                  required
                  disabledBefore={ yesterday.setDate( today.getDate() - 2 ) }
                  name={ `extend_date` }
                  label={ `Date` }
                  placeholder={ "Enter date" }
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                    setMessage( "" );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name={ `extend_date` }
              control={ control }
            />

            <ResponseMessage message={ message } />
            <div className="form-footer-style display-footer-right">
              <div className="form-footer-elements">
                <PrimaryButton
                  text={ "Update" }
                  type="submit"
                  loading={ saveLoading }
                  disabled={ saveLoading ? true : false }
                />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default UsersExtendTrial;
