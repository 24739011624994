import { AlertContext } from "../../context/AlertContext";
import { api } from "./main";

import { useContext } from "react";

export const getAllCoupons = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `admin/v1/coupon${ page }`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
export const getCouponById = ( payload ) => {
  return api
    .get( `admin/v1/coupon/${ payload }`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const createCoupon = async ( payload ) => {
  return await api
    .post( "admin/v1/coupon", payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const editCoupon = async ( payload ) => {
  return await api
    .put( `admin/v1/coupon/${ payload?.id }`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const deleteCoupon = async ( payload ) => {
  return await api
    .delete( `admin/v1/coupon/${ payload }` )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const getCouponUsers = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `admin/v1/coupon-statistic/${ payload?.id }${ page }`, {
      params: { ...payload },
    } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const exportCouponUsers = async ( payload ) => {
  return await api
    .get( `admin/v1/export-coupon-details/${ payload?.id }`, {
      params: { ...payload },
      responseType: "blob",
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const exportCoupons = async ( payload ) => {
  return await api
    .get( `admin/v1/export-coupons`, {
      params: { ...payload },
      responseType: "blob",
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
