import { api } from "./main";

export const getAllSessions = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `admin/v1/get-all-sessions${ page }`, {
      params: { ...payload },
    } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const checkSessionName = async ( payload ) => {
  return await api
    .get( `admin/v1/check-session-name`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const createSession = async ( payload ) => {
  return await api
    .post( `admin/v1/create-session`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const sendSessionInvitation = async ( payload ) => {
  return await api
    .post( `v1/send-invitation`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const deleteSession = async ( payload ) => {
  return await api
    .delete( `admin/v1/delete-session/${ payload }` )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const startSession = async ( payload ) => {
  return await api
    .patch( `admin/v1/start-session/${ payload }` )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

