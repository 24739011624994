import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ImageDelete, ImageDrag, StarIcon } from "../../../assets/svg/SVG";
import { imagesURL } from "../../../core/apis/main";

export function ImageComponent( props ) {
  const { background, data, handleDelete, canDrag, loadingAvailable } = props;
  //loading Drag is to remove drag icon from pictures when there is drag to not drag any other image before backend call
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable( { id: data?.id } );

  const style = {
    transform: CSS.Transform.toString( transform ),
    transition,
    cursor: canDrag ? "grab" : "",
  };

  return (
    <div
      ref={ setNodeRef }
      style={ style }
      { ...attributes }
      className="image-component"
    >
      <div className="image-container">
        <img
          className={ `${ isDragging && "selected-image" }` }
          src={ imagesURL + data.path }
          width={ "120px" }
          height={ background ? "80px" : "120px" }
        />

        { !loadingAvailable ? !canDrag ? (
          <div className={ `image-vector` } onClick={ () => handleDelete( data ) }>
            <ImageDelete />
          </div>
        ) : (
          <div className={ `image-vector` } { ...listeners }>
            <ImageDrag />
          </div>
        ) : "" }
      </div>

      <div className="createdby-username">
        { data?.is_admin === 1 ? (
          <div className="admin-name">
            Admin
            <span className="admin-start">
              <StarIcon />
            </span>
          </div>
        ) : (
          data?.user?.first_name + " " + data?.user?.last_name
        ) }
      </div>
    </div>
  );
}
export default ImageComponent;
