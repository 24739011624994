//HERE ARE THE COMPONENT THAT CAN BE ADDED TO A FORM AS COMPONENT (REUSABLE)

//UTILITIES
import React, { useEffect, useRef, useState } from "react";
import palette from "../../assets/theme/color.scss"; //import of colors to be added if needed
import PropTypes from "prop-types";
import moment from "moment";
//COMPONENT (MUI)
import { DateRange } from "react-date-range";
import InfiniteScroll from "react-infinite-scroller";
import { Skeleton, Paper } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import PhoneInput from "react-phone-number-input";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { CardMedia, IconButton, LinearProgress, Tooltip } from "@mui/material";
import {
  Slider,
  Avatar,
  TextField,
  Checkbox,
  FormGroup,
  Switch,
} from "@mui/material";
import { CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { RadioGroup, Radio, Autocomplete, Button } from "@mui/material";
import { Chip } from "@mui/material";
import { ListItemAvatar, FormControl, FormHelperText } from "@mui/material";
import { InputLabel, FormControlLabel, InputAdornment } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
//ICONS
import "react-phone-number-input/style.css";
import "./FormComponent.scss";
import {
  BlockEyeSVG,
  DeleteSVG,
  EyeSVG,
  MoreInfoSVG,
  SearchIcon,
  UploadIcon,
} from "../../assets/svg/SVG";
//CSS
import "./FormComponent.scss";
import { useDropzone } from "react-dropzone";
import { Check } from "@material-ui/icons";
import { LinkButton } from "../buttons/Buttons";
import { imagesURL } from "../../core/apis/main";
import { parsePhoneNumber } from "react-phone-number-input";

const RenderFileInfo = ( sizeMax, fileType, aspectRatio ) => {
  return (
    <div className="file-requirements-tooltip">
      <div>Requirements :</div>
      <li>{ fileType?.toString() }</li>
      <li>Up to { sizeMax } MB </li>
      { aspectRatio && <li>Ratio { aspectRatio }</li> }
    </div>
  );
};

const getMarksArray = ( min, max, step ) => {
  let array = [];
  for ( let i = min; i <= max; i = i + step ) {
    array.push( { value: i, label: i } );
  }

  return array;
};

//INPUT (USED FOR ALL INPUTS : EMAIL , INPUT , NUMBER)
//TYPE :TEXT OR NUMBER

export const FormInput = ( props ) => {
  const { helperText, disabled, label, placeholder, className } = props;
  const { value, onChange, required, type, name, maxLength, variant } = props;

  return (
    <div className="form-input-wrapper" style={ { width: "100%" } }>
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? " * " : "" }</span>
        </InputLabel>
      ) }
      <TextField
        fullWidth
        size="small"
        variant={ variant }
        type={ type }
        className={ className }
        value={ value }
        maxLength={ maxLength }
        placeholder={ placeholder }
        helperText={ helperText }
        disabled={ disabled }
        onChange={ ( e ) => onChange( e.target.value ) }
        name={ name }
        inputProps={ {
          maxLength: maxLength,
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
        } }
      />
    </div>
  );
};
FormInput.defaultProps = {
  variant: "outlined",
  maxLength: null,
};
FormInput.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  maxLength: PropTypes.any,
};
//INPUT FOR PASSWORD
export const FormPassword = ( props ) => {
  const { label, placeholder, value, onChange, required, helperText } = props;
  const [ showPassword, setShowPassword ] = useState( false );

  const handleClickShowPassword = () => {
    setShowPassword( !showPassword );
  };

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { label }
        <span className="required-start">{ required ? " * " : "" }</span>
      </InputLabel>
      <TextField
        autoComplete="new-password"
        fullWidth
        size="small"
        placeholder={ placeholder }
        variant="outlined"
        type={ showPassword ? "text" : "password" }
        onChange={ ( e ) => onChange( e.target.value ) }
        value={ value }
        helperText={ helperText }
        inputProps={ {
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
        } }
        InputProps={ {
          endAdornment: (
            <InputAdornment
              position={ "end" }
            >
              <IconButton onClick={ handleClickShowPassword }>
                { showPassword ? <BlockEyeSVG /> : <EyeSVG /> }
              </IconButton>
            </InputAdornment>
          ),
        } }
      />
    </div>
  );
};

//DROPDOWN LIST
export const FormDropdownList = ( props ) => {
  const {
    label,
    name,
    placeholder,
    type,
    disabledOptions,
    variant,
    disabled,
    required,
    value,
    dependencyError,
    formComponent,
  } = props;
  const {
    data,
    noOptionsMessage,
    loading,
    onChange,
    helperText,
    clearErrors,
    accessValue,
  } = props;

  const [ val, setVal ] = useState( null );

  useEffect( () => {
    if ( value ) {
      setVal( value );
      if ( clearErrors ) {
        dependencyError ? clearErrors( dependencyError ) : clearErrors( name );
      }
    }
  }, [ value ] );

  return (
    <div className={ formComponent ? `form-input-wrapper` : "non-form-wrapper" }>
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required && " * " }</span>
        </InputLabel>
      ) }

      <Autocomplete
        size="small"
        disabled={ disabled }
        fullWidth
        getOptionDisabled={ ( option ) => disabledOptions?.includes( option?.slug ) }
        disableClearable={ required }
        ListboxProps={ { style: { maxHeight: 200, overflow: "auto" } } }
        getOptionLabel={ ( option ) =>
          option?.[ accessValue ]
            ? typeof option?.[ accessValue ] === "object"
              ? option?.[ accessValue ][ "en" ]
              : option?.[ accessValue ]
            : "Error displaying options"
        }
        options={ data }
        value={ value || null }
        isOptionEqualToValue={ ( option, value ) => option.id === value?.id }
        loadingText={ "Loading" }
        noOptionsText={ noOptionsMessage ? noOptionsMessage : "No Options" }
        loading={ loading }
        onChange={ ( event, selected ) => {
          onChange( selected );
        } }
        renderInput={ ( params ) => (
          <TextField
            { ...params }
            variant={ variant }
            placeholder={ placeholder }
            helperText={ helperText }
            InputProps={ {
              ...params.InputProps,
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
              endAdornment: (
                <React.Fragment>
                  { loading ? (
                    <CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </React.Fragment>
              ),
            } }
          />
        ) }
      />
    </div>
  );
};

FormDropdownList.defaultProps = {
  accessValue: "name",
  variant: "outlined",
  formComponent: true,
};

FormDropdownList.propTypes = {
  accessValue: PropTypes.string,
  variant: PropTypes.string,
  formComponent: PropTypes.bool,
};

export const UploadComponent = ( props ) => {
  const { setFieldValue, value, helperText } = props;
  const [ files, setFiles ] = useState( value || [] );
  const { getRootProps, getInputProps } = useDropzone( {
    accept: "image/*",
    multiple: false,
    onDrop: ( acceptedFiles ) => {
      setFieldValue( "image", acceptedFiles?.[ 0 ] );
      setFiles( acceptedFiles?.[ 0 ] );
    },
  } );
  return (
    <div { ...getRootProps( { className: "dropzone" } ) }>
      <input { ...getInputProps() } />
      <p>Drag and drop some files here, or click to select files</p>
      { Object.values( files ).length || typeof files === "string" ? (
        <>
          <img
            style={ { objectFit: "contain" } }
            alt="the uploaded file"
            width="100px"
            height="100px"
            src={
              files instanceof File
                ? URL.createObjectURL( files )
                : imagesURL + files
            }
          />
          <Button
            onClick={ ( e ) => {
              e.stopPropagation();
              setFiles( [] );
              setFieldValue( "image", null );
            } }
            variant="text"
            startIcon={ <DeleteSVG /> }
          >
            Remove
          </Button>
        </>
      ) : null }
      { helperText !== "" && <FormHelperText>{ helperText }</FormHelperText> }
    </div>
  );
};

//SWITCH
export const FormSwitch = ( props ) => {
  const { value, label, onChange, name, disabled } = props;
  return (
    <div className="form-input-wrapper">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className="form-switch-label"
          control={
            <Switch
              name={ name }
              checked={ value }
              color="primary"
              value={ value }
              onChange={ onChange }
              disabled={ disabled }
            />
          }
          label={ <b style={ { color: palette.primaryColor } }>{ label }</b> }
          labelPlacement="start"
        />
      </FormGroup>
    </div>
  );
};

//TEXTAREA
export const FormTextArea = ( props ) => {
  //TRANSLATION
  const { rows, label, value, required } = props;
  const { onChange, helperText, placeholder, disabled } = props;

  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <TextField
        fullWidth
        multiline
        rows={ rows || 4 }
        size="small"
        value={ value }
        placeholder={ placeholder }
        variant="outlined"
        onChange={ onChange }
        helperText={ helperText }
        disabled={ disabled }
      />
    </div>
  );
};

//CALENDAR
export const FormDatePicker = ( props ) => {
  const { required, label, onChange, minDate, inputFormat } = props;
  const { helperText, disabledAfter, value, views } = props;

  //IF HE WANTS TO DISABLED SOME DATES
  const disabledDate = ( date ) => {
    if ( disabledAfter ) {
      if ( date > new Date() ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <DesktopDatePicker
          className="date-picker-style"
          fullWidth
          views={ views }
          minDate={ minDate ? minDate : null }
          inputFormat={ inputFormat }
          value={ value }
          shouldDisableDate={ disabledDate }
          onChange={ onChange }
          renderInput={ ( params ) => (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              { ...params }
              error={ false }
              helperText={ helperText }
            />
          ) }
          PaperProps={ {
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  color: palette.whiteColor,
                },
              },
            },
          } }
        />
      </LocalizationProvider>
    </div>
  );
};

FormDatePicker.defaultProps = {
  views: [ "year", "month", "day" ],
  inputFormat: "dd-MM-yyyy",
};

FormDatePicker.propTypes = {
  views: PropTypes.array,
};

//FORM TIME PICKER
export const FormTimePicker = ( props ) => {
  const {
    required,
    label,
    value,
    onChange,
    clearErrors,
    helperText,
    disabled,
    name,
    minTime,
  } = props;

  const selected_value = value ? new Date( `1995-12-17T${ value }` ) : null;

  const handleOnChange = ( new_value ) => {
    onChange( new_value !== null ? moment( new_value ).format( "HH:mm" ) : null );
    clearErrors( name );
  };

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { label }
        <span className="required-start">{ required ? "*" : "" }</span>
      </InputLabel>
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <TimePicker
          name={ name }
          disabled={ disabled }
          className="date-picker-style"
          fullWidth
          minTime={ minTime }
          ampm={ true }
          inputFormat="HH:mm"
          value={ selected_value }
          onChange={ ( e ) => handleOnChange( e ) }
          renderInput={ ( params ) => (
            <TextField
              size="small"
              fullWidth
              { ...params }
              error={ false }
              helperText={ helperText }
            />
          ) }
        />
      </LocalizationProvider>
    </div>
  );
};

export const FormMultiSelect = ( props ) => {
  const {
    label,
    data,
    disabled,
    required,
    onChange,
    value,
    fixedOptions,
    limitTags,
    variant,
    name,
    accessValue,
  } = props;
  const {
    helperText,
    loading,
    placeholder,
    noOptionsMessage,
    clearErrors,
    dependencyError,
  } = props;

  const [ val, setVal ] = useState( fixedOptions ? [ ...fixedOptions ] : [] );

  //  const filter = createFilterOptions();
  // const allSelected = data?.length === value?.length;

  useEffect( () => {
    if ( value ) {
      setVal( value );
      if ( clearErrors ) {
        dependencyError ? clearErrors( dependencyError ) : clearErrors( name );
      }
    }
  }, [ value ] );

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { label } <span className="required-start">{ required ? "*" : "" }</span>
      </InputLabel>

      <Autocomplete
        multiple
        size="small"
        limitTags={ limitTags }
        fullWidth
        options={ data }
        disabled={ disabled }
        ListboxProps={ { style: { maxHeight: 200, overflow: "auto" } } }
        getOptionLabel={ ( option ) =>
          typeof option?.[ accessValue ] === "object"
            ? option?.[ accessValue ][ "en" ]
            : option?.[ accessValue ]
        }
        loading={ loading }
        noOptionsText={ noOptionsMessage ? noOptionsMessage : "No Options" }
        loadingText={ "Loading" }
        value={ val }
        isOptionEqualToValue={ ( option, value ) => option?.id === value?.id }
        onChange={ ( event, newValue ) => {
          if ( newValue.some( ( option ) => option.value === "select-all" ) ) {
            onChange( data );
          } else {
            onChange( [
              ...new Map(
                [ ...fixedOptions, ...newValue ].map( ( item ) => [ item[ "id" ], item ] )
              ).values(),
            ] );
          }
        } }
        // filterOptions={(options, params) => {
        //   const filtered = filter(options, params);
        //   return [{ name: "Select All", value: "select-all" }, ...filtered];
        // }}
        renderInput={ ( params ) => (
          <TextField
            { ...params }
            variant={ variant }
            placeholder={ placeholder }
            helperText={ helperText }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  { loading ? (
                    <CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </React.Fragment>
              ),
            } }
          />
        ) }
        renderTags={ ( tagValue, getTagProps ) =>
          tagValue.map( ( option, index ) => (
            <Chip
              key={ option?.id }
              color="primary"
              variant="outlined"
              label={
                typeof option?.[ accessValue ] === "object"
                  ? option?.[ accessValue ][ "en" ]
                  : option?.[ accessValue ]
              }
              { ...getTagProps( { index } ) }
              disabled={ fixedOptions.find( ( val ) =>
                val?.id === option?.id ? true : false
              ) }
            />
          ) )
        }
        renderOption={ ( props, option, { selected } ) => {
          // const selectAllProps =
          //   option.value === "select-all" // To control the state of 'select-all' checkbox
          //     ? { checked: allSelected }
          //     : {};

          return (
            <div { ...props }>
              { typeof option?.[ accessValue ] === "object"
                ? option?.[ accessValue ][ "en" ]
                : option?.[ accessValue ] }
            </div>
          );
        } }
      />
    </div>
  );
};
FormMultiSelect.defaultProps = {
  accessValue: "name",
  variant: "outlined",
  formComponent: true,
  fixedOptions: [],
  limitTags: 4,
};

FormMultiSelect.propTypes = {
  accessValue: PropTypes.string,
  variant: PropTypes.string,
  formComponent: PropTypes.bool,
  fixedOptions: PropTypes.array,
  limitTags: PropTypes.number,
};
//CHECKBOX

export const FormMultipleCheckBox = ( props ) => {
  const { onChange, data, required, label, value, helperText } = props;

  const handleOnChange = ( item, booleanValue ) => {
    let array = value;

    let changed_one = data.findIndex( ( d ) => d.value === item.value );
    if ( booleanValue === false ) {
      array.splice( changed_one, 1 );
    } else {
      array.push( item.value );
    }

    onChange( array );
  };
  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { label }
        <span className="required-start">{ required ? "*" : "" }</span>
      </InputLabel>
      <FormControl>
        <FormGroup row>
          { data.map( ( item ) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={value.includes(item.value)}
                    onChange={ ( e, value ) => handleOnChange( item, value ) }
                    inputProps={ { "aria-label": "controlled" } }
                  />
                }
                label={ item.name }
              />
            );
          } ) }
        </FormGroup>
        <FormHelperText>{ helperText }</FormHelperText>
      </FormControl>
    </div>
  );
};

export const FormCheckBox = ( props ) => {
  const { onChange, checked, label, value, required, helperText } = props;

  const handleChange = ( value ) => {
    onChange( value );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={ value }
            value={ value }
            onChange={ ( e, value ) => handleChange( value ) }
            inputProps={ { "aria-label": "controlled" } }
          />
        }
        label={
          <span>
            { label }
            <span className="required-start">{ required ? "*" : "" }</span>
          </span>
        }
      />
      { helperText && helperText !== "" && (
        <FormControl>
          <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
      ) }
    </FormGroup>
  );
};
export const FormRadioButton = ( props ) => {
  const { onChange, data, required, label, value, helperText } = props;
  const { disabled, name, loading, row } = props;

  const [ val, setVal ] = useState( null );
  useEffect( () => {
    setVal( value );
  }, [ value, data ] );

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { label }
        <span className="required-start">{ required ? "*" : "" }</span>
      </InputLabel>
      { !loading ? (
        <FormControl>
          <RadioGroup row={ row } value={ val?.id } name={ name }>
            { data?.map( ( item ) => {
              return (
                <>
                  <FormControlLabel
                    key={ item.id }
                    disabled={ disabled }
                    value={ item.id }
                    control={ <Radio checked={ val?.id == item.id } /> }
                    label={ item.name }
                    onClick={ ( e ) => ( disabled ? null : onChange( item ) ) }
                  />
                  { item.icon && (
                    <div className="radio-button-icon">{ item.icon }</div>
                  ) }
                </>
              );
            } ) }
          </RadioGroup>

          { helperText !== "" && <FormHelperText>{ helperText }</FormHelperText> }
        </FormControl>
      ) : (
        <LinearProgress color="primary" />
      ) }
    </div>
  );
};
FormRadioButton.defaultProps = {
  row: true,
};
FormRadioButton.propTypes = {
  row: PropTypes.bool,
};

//FORM SINGLE UPLOAD
export const FormSingleUpload = ( props ) => {
  const { type, label, onChange, accept } = props;
  const { aspectRatio, value, name, helperText } = props;

  const handleOnChange = ( files ) => onChange( files );
  const handleRemoveImage = () => onChange( null );

  let file_type =
    type === "File"
      ? accept
      : type === "Video"
        ? [ "MP4", "MOV", "OGG", "QT" ]
        : [ "JPG", "PNG" ];
  let size_max = type === "File" ? 10 : type === "Video" ? 5 : 1;
  return (
    <div className="form-input-wrapper">
      { !value ? (
        <div className="form-upload-section">
          <FileUploader
            maxSize={ size_max }
            types={ file_type }
            name={ name }
            onSizeError={ ( error ) => console.log( error ) }
            onTypeError={ ( error ) => console.log( error ) }
            multiple={ false }
            handleChange={ ( files ) => handleOnChange( files ) }
            children={
              <div className="form-upload-children">
                <Button startIcon={ <UploadIcon /> } name={ label } />
                <div className="ratio-label">
                  { aspectRatio && `(Ratio : ${ aspectRatio } )` }
                </div>
              </div>
            }
          />
          { helperText && (
            <FormControl>
              <FormHelperText>{ helperText }</FormHelperText>
            </FormControl>
          ) }
        </div>
      ) : (
        <>
          <img
            alt="the uploaded file"
            width="100px"
            height="100px"
            src={
              value && value instanceof File
                ? URL.createObjectURL( value )
                : imagesURL + value
            }
          />
          <div
            style={ {
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
            } }
          >
            <Button
              onClick={ handleRemoveImage }
              variant="text"
              startIcon={ <DeleteSVG /> }
            >
              Remove
            </Button>
          </div>
        </>
      ) }
    </div>
  );
};
FormSingleUpload.defaultProps = {
  label: "Drop your image here or browse",
  aspectRatio: "1:1",
};
FormSingleUpload.propTypes = {
  type: PropTypes.oneOf( [ "News", "Video" ] ),
  label: PropTypes.string,
  aspectRatio: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
//FORM MULTIPLE UPLOAD
export const FormMultipleUpload = ( props ) => {
  const {
    name,
    label,
    accept,
    helperText,
    onChange,
    type,
    value,
    handleDeleteImage,
  } = props;

  const handleOnChange = ( files ) => onChange( files );

  let file_type =
    type === "File"
      ? accept
      : type === "Image"
        ? [ "JPG", "PNG" ]
        : [ "JPG", "PNG" ];

  let size_max = type === "File" ? 10 : type === "Video" ? 5 : 1;

  return (
    <div className="form-input-wrapper">
      { !value && (
        <div className="form-upload-section">
          <FileUploader
            name={ name }
            maxSize={ size_max }
            types={ file_type }
            onTypeError={ ( error ) => {
              console.log( error );
            } }
            onSizeError={ ( error ) => {
              console.log( error );
            } }
            classes={ "" }
            multiple={ false }
            handleChange={ ( files ) => {
              handleOnChange( files );
            } }
            children={
              <div className="form-upload-children">
                <Button startIcon={ <UploadIcon /> } name={ label } />
                <div className="ratio-label">Upload multiple images</div>
              </div>
            }
          />
        </div>
      ) }

      { value && value.length !== 0 && (
        <div className="list-uploaded-images">
          <List className="list-images">
            { value?.map( ( item, index ) => {
              return (
                <ListItem
                  className="image-uploaded-item"
                  secondaryAction={
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      onClick={ () => handleDeleteImage( item, index ) }
                    >
                      <input hidden accept="image/*" type="file" />
                      <DeleteSVG />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      src={
                        item
                          ? item.path
                            ? imagesURL + item.path
                            : URL.createObjectURL( item )
                          : null
                      }
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ item.name ? item.name : item.path.split( "/" ).pop() }
                  />
                </ListItem>
              );
            } ) }
          </List>
        </div>
      ) }
      { helperText && (
        <FormControl>
          <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
      ) }
    </div>
  );
};

export const FormSearchBar = ( props ) => {
  const { placeholder, variant, value, onChange } = props;

  const handleInputChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <TextField
      type="text"
      fullWidth
      size="small"
      placeholder={ placeholder }
      variant={ variant }
      onChange={ ( e ) => handleInputChange( e ) }
      value={ value }
      InputProps={ {
        startAdornment: (
          <InputAdornment className="input-adorment" position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      } }
    />
  );
};
export const FormPhoneInput = ( props ) => {
  const {
    required,
    name,
    onChange,
    label,
    country,
    value,
    helperText,
    validation,
    toggleError,
  } = props;

  const handleOnChange = ( value ) => {
    if ( value ) {
      const getCountry = parsePhoneNumber( value );
      if ( getCountry?.country ) {
        onChange( value, false );
      } else {
        onChange( value, true );
      }
    } else {
      onChange( value, true );
    }
  };

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { " " }
        { label }
        <span className="required-start">{ required ? " * " : "" }</span>
      </InputLabel>
      <PhoneInput
        international
        className="phone-input-style"
        defaultCountry="ES"
        value={ value }
        onChange={ ( value, country ) => handleOnChange( value, country ) }
      />

      { helperText !== "" && <FormHelperText>{ helperText }</FormHelperText> }
    </div>
  );
};


FormInput.defaultProps = {
  variant: "outlined",
  maxLength: null,
};
FormInput.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  maxLength: PropTypes.any,
};

export const FormPromoCode = ( props ) => {
  const { helperText, disabled, label, placeholder, className } = props;
  const { value, planId, onChange, required, type, name, maxLength, variant } =
    props;

  return (
    <div className="form-input-wrapper" style={ { width: "100%" } }>
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? " * " : "" }</span>
          <Tooltip
            title={
              "If you have a valid promo code,please write it down, otherwise leave this field empty"
            }
            placement={ "right-start" }
            arrow={ true }
          >
            <span className="cursor-pointer">
              <MoreInfoSVG />
            </span>
          </Tooltip>
        </InputLabel>
      ) }
      <TextField
        fullWidth
        size="small"
        variant={ variant }
        type={ type }
        className={ className }
        value={ value }
        maxLength={ maxLength }
        placeholder={ placeholder }
        helperText={ helperText }
        disabled={ disabled }
        onChange={ onChange }
        name={ name }
        InputProps={ {
          endAdornment: (
            <InputAdornment
              position={ "end" }
              sx={ {
                backgroundColor: palette.whiteColor,
              } }
            >
              <IconButton>
                { value !== "" &&
                  value !== null &&
                  ( helperText === "" || !helperText ) ? (
                  <Check style={ { color: palette.greenColor } } />
                ) : null }
              </IconButton>
            </InputAdornment>
          ),
        } }
      />
    </div>
  );
};
FormInput.defaultProps = {
  variant: "outlined",
  maxLength: null,
};
FormInput.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  maxLength: PropTypes.any,
};
export const FormSingleDashedUpload = ( props ) => {
  const {
    type,
    downloadTitle,
    required,
    onChange,
    accept,
    aspectRatio,
    cropped,
    value,
    maxSize,
    name,
    helperText,
  } = props;

  const cropperRef = useRef();
  const [ croppedImage, setCroppedImage ] = useState( null );
  const handleOnChange = ( files ) => {
    onChange( files );
    setCroppedImage( URL.createObjectURL( files ) );
  };

  const urlToFile = async ( url, filename, mimeType ) => {
    const res = await fetch( url );
    const buf = await res.arrayBuffer();
    return new File( [ buf ], filename, { type: mimeType } );
  };

  const handleOnCrop = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    let cropped_image = cropper?.getCroppedCanvas().toDataURL();
    if ( cropped_image ) {
      let upload_image = await urlToFile(
        cropped_image,
        value?.name,
        "text/plain"
      );

      onChange( upload_image );
    }
  };

  const handleRemoveImage = () => {
    onChange( null );
  };

  let file_type =
    type === "File"
      ? accept
      : type === "Video"
        ? [ "MP4", "MOV", "OGG", "QT" ]
        : [ "JPG", "PNG" ];

  let size_max = type === "File" ? 10 : type === "Video" ? 5 : 1;

  return (
    <div className="form-input-wrapper">
      <>
        { !value ? (
          <div className="form-upload-section">
            <FileUploader
              maxSize={ size_max }
              types={ file_type }
              name={ name }
              onTypeError={ ( error ) => { } }
              onSizeError={ ( error ) => { } }
              classes={ "multiple-file-upload" }
              multiple={ false }
              handleChange={ ( files ) => {
                handleOnChange( files );
              } }
              children={
                <>
                  <UploadIcon />

                  <span>
                    { downloadTitle }
                    <span className="error-start">
                      { required ? "*" : "" }
                      <Tooltip
                        title={ RenderFileInfo(
                          size_max,
                          file_type,
                          aspectRatio == 16 / 9 ? "16/9" : "1/1"
                        ) }
                        placement={ "bottom-end" }
                        arrow={ true }
                      >
                        <span className="cursor-pointer">
                          <MoreInfoSVG />
                        </span>
                      </Tooltip>
                    </span>
                  </span>
                </>
              }
            />
          </div>
        ) : cropped ? (
          <>
            <Cropper
              ref={ cropperRef }
              style={ { height: 300, width: "100%" } }
              zoomTo={ 0.5 }
              initialAspectRatio={ 1 }
              aspectRatio={ aspectRatio }
              src={ croppedImage }
              viewMode={ 1 }
              crop={ handleOnCrop }
              // minCropBoxHeight={10}
              // minCropBoxWidth={"500px"}
              background={ true }
              responsive={ true }
              autoCropArea={ 1 }
              checkOrientation={ false }
              guides={ false }
            />
            <div
              style={ {
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "flex-end",
              } }
            >
              <LinkButton
                startIcon={ <DeleteSVG /> }
                text="Remove"
                deleteButton
                onClick={ () => handleRemoveImage() }
              />
            </div>
          </>
        ) : (
          <div>
            <CardMedia
              component="img"
              alt="Image"
              height="200px"
              image={
                value
                  ? value instanceof File
                    ? URL.createObjectURL( value )
                    : imagesURL + value
                  : null
              }
            />
            <div
              style={ {
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "center",
                justifyContent: "flex-end",
              } }
            >
              <LinkButton
                startIcon={ <DeleteSVG /> }
                deleteButton
                text="Remove"
                default
                onClick={ () => handleRemoveImage() }
              />
            </div>
          </div>
        ) }
        { helperText && (
          <FormControl>
            <FormHelperText>{ helperText }</FormHelperText>
          </FormControl>
        ) }
      </>
    </div>
  );
};
FormSingleDashedUpload.defaultProps = {
  downloadTitle: "Drop your file(s) here or browse",
};
FormSingleDashedUpload.propTypes = {
  downloadTitle: PropTypes.string,
};

export const FormNumberInput = ( props ) => {
  const {
    label,
    value,
    placeholder,

    required,
    percent,
    disabled,
    price,

    onChange,
    helperText,
  } = props;

  const handleOnChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel style={ props.style }>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <TextField
        type="number"
        className={ props.className }
        fullWidth
        size="small"
        value={ value }
        placeholder={ placeholder }
        variant="outlined"
        onChange={ handleOnChange }
        helperText={ helperText }
        disabled={ disabled ? disabled : false }
        InputProps={ {
          startAdornment: percent ? (
            <InputAdornment position="start">%</InputAdornment>
          ) : price ? (
            <InputAdornment position="start">{ price }</InputAdornment>
          ) : (
            ""
          ),
        } }
      />
    </div>
  );
};
export const FormPaginationDropdownList = ( props ) => {
  const {
    name,
    label,
    value,
    validation,
    placeholder,
    data,
    type,
    disabled,
    formComponent,
    noOptionsMessage,
    loading,
    onChange,
    required,
    helperText,
    handlePageChange,
    handleFilter,
  } = props;

  const handleOnChange = ( selected ) => {
    onChange( selected ? selected : null );
  };

  const filteredData = async ( inputValue ) => {
    let response = await handleFilter( inputValue );

    return data?.data || [];
  };

  const filterOptions = ( options, { inputValue } ) =>
    inputValue ? filteredData : options;
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">
            { required && !formComponent ? "*" : "" }
          </span>
        </InputLabel>
      ) }

      <Autocomplete
        size="small"
        disabled={ disabled ? disabled : false }
        fullWidth
        disableClearable={ required ? true : false }
        filterOptions={ filterOptions }
        // ListboxProps={{
        //   style: {
        //     maxHeight: "150px",
        //     overflowX: "hidden",
        //     overflowY: "scroll",
        //   },
        // }}
        getOptionLabel={ ( option ) => option?.name }
        options={ data?.data || [] }
        value={ value }
        isOptionEqualToValue={ ( option, value ) => option.id === value?.id }
        loadingText={ "Loading..." }
        noOptionsText={ noOptionsMessage ? noOptionsMessage : "No Options" }
        loading={ loading }
        onChange={ ( event, selected ) => {
          handleOnChange( selected );
        } }
        PaperComponent={ ( { children, ...other } ) => (
          <Paper
            { ...other }
            style={ {
              maxHeight: "100px",
              overflowX: "hidden",
              overflowY: "scroll",
            } }
          >
            <InfiniteScroll
              pageStart={ 0 }
              initialLoad={ false }
              loadMore={ handlePageChange }
              hasMore={ !loading && data?.page < data?.totalPage ? true : false }
              loader={ <Skeleton /> }
              useWindow={ false }
              threshold={ 250 }
            // endMessage={
            //   <p style={{ textAlign: "center", backgroundColor: "#f9dc01" }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            >
              { children }
            </InfiniteScroll>
          </Paper>
        ) }
        renderInput={ ( params ) => (
          <TextField
            { ...params }
            placeholder={ placeholder }
            helperText={ helperText }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  { loading ? (
                    <CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </React.Fragment>
              ),
            } }
          />
        ) }
      />
    </div>
  );
};
export const FormCalendarInput = ( props ) => {
  const {
    required,
    label,
    value,
    onChange,
    helperText,
    minDate,
    disabledAfter,
    disabledBefore,
    tooltip,
    maxDate,
    name,
  } = props;

  const disabledDate = ( date ) => {
    if ( disabledAfter ) {
      if ( date > disabledAfter ) {
        return true;
      } else {
        return false;
      }
    } else if ( disabledBefore ) {
      if ( date < disabledBefore ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
          { tooltip && (
            <Tooltip title={ tooltip } placement={ "right-start" } arrow={ true }>
              <span className="cursor-pointer  margin-right-10">
                <MoreInfoSVG />
              </span>
            </Tooltip>
          ) }
        </InputLabel>
      ) }
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <DesktopDatePicker
          className="date-picker-style"
          fullWidth
          inputFormat="dd-MM-yyyy"
          value={ value }
          maxDate={ maxDate }
          minDate={ minDate }
          shouldDisableDate={ disabledDate }
          onChange={ onChange }
          renderInput={ ( params ) => (
            <TextField
              size="small"
              fullWidth
              { ...params }
              error={ false }
              helperText={ helperText }
            />
          ) }
          PaperProps={ {
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  color: palette.whiteColor,
                },
              },
            },
          } }
        />
      </LocalizationProvider>
    </div>
  );
};

FormCalendarInput.defaultProps = {
  maxDate: null,
  minDate: null,
};

FormCalendarInput.propTypes = {
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
};

export const FormFilterDateRange = ( props ) => {
  const {
    required,
    label,
    value,
    onChange,
    helperText,
    toggleError,
    validation,
    disabledAfter,
    name,
  } = props;

  const handleChange = ( dates ) => {
    onChange( dates.selection );
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { " " }
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <DateRange
        ranges={ [
          { ...value, key: "selection", color: palette.primaryColorLight },
        ] }
        onChange={ handleChange }
        rangeColors={ "#3d91ff" }
      />
    </div>
  );
};
export const FormFilterAmountRange = ( props ) => {
  const {
    required,
    label,
    value,
    onChange,
    helperText,
    toggleError,
    validation,
    disabledAfter,
    name,
  } = props;

  const handleNumberChange = ( event, newValue ) => {
    onChange( [ newValue[ 0 ], newValue[ 1 ] ] );
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { " " }
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <Slider
        min={ 0 }
        step={ 100 }
        marks={ getMarksArray( 0, 500, 100 ) }
        max={ 500 }
        getAriaLabel={ () => "Temperature range" }
        value={ value }
        onChange={ handleNumberChange }
      />
    </div>
  );
};
