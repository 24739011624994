//UTILITIES
import * as yup from "yup";
import palette from "../../../assets/theme/color.scss";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect, useContext } from "react";
//COMPONENT
import { PrimaryButton } from "../../../components/buttons/Buttons";
import { ImageSkeletonComponent } from "../../../components/loader-component/LoaderComponent";
import NoDataFound from "../../../components/response-messages/NoDataFound";

import ImageHandle from "../image-management/ImageHandle";
//API
import { getAllImages } from "../../../core/apis/image";
//CSS
import "./Sessions.scss";
import { FormControl, FormHelperText } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";

import { imagePerPage } from "../../../core/variables/CoreVariables";

import { imagesURL } from "../../../core/apis/main";
import LoadMorePagination from "../../../components/table-component/LoadMorePagination";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function SessionImage( props ) {
  const { slug, SubmitStep, activeStep, handleStep, formValues } = props;
  const [ page, setPage ] = useState( 1 );
  const [ loadMore, setLoadMore ] = useState( false );
  const [ total, setTotal ] = useState( 0 );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const { setAlert } = useContext( AlertContext );

  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ openAddImage, setOpenAddImage ] = useState( false );
  const [ data, setData ] = useState( [] );

  const HandleSchema = yup.object( {
    backgroundId:
      slug === "background"
        ? yup.object().nullable().required( "Please select background image" )
        : yup.object().nullable(),
    boardId:
      slug === "board"
        ? yup.object().nullable().required( "Please select board image" )
        : yup.object().nullable(),
  } );
  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    setValue,
    getValues,

    formState: { errors },
  } = useForm( {
    defaultValues: {
      backgroundId: formValues?.backgroundId || null,
      boardId: formValues?.boardId || null,
    },
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleSubmitForm = async ( formData ) => {
    setSaveLoading( true );
    let result = await SubmitStep( formData );
    if ( !result ) {
      setSaveLoading( false );
    }
  };

  const handleCloseHandleImage = () => {
    setOpenAddImage( false );
  };

  const handleImageHandleResponse = ( value ) => {
    setOpenAddImage( false );
    setValue( `${ slug }Id`, value, {
      shouldValidate: true,
    } );
    GetData();
  };
  //GET PAGINATED DATA (IF WE CLICK VIEW MORE TO COMMENT )
  const GetPaginatedData = ( new_page ) => {
    setLoadMore( true );
    let old = [ ...data ];
    getAllImages( {
      category_slug: slug,
      per_page: imagePerPage,
      page: new_page,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setPage( response.data.data?.current_page );
        if ( response.data.data ) {
          response.data.data.data?.map( ( item ) => old.push( item ) );
        }

        setData( old );
        setTotalPage( response.data.data?.last_page );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response?.data?.success ? false : true,
        } );
      }
      setLoadMore( false );
    } );
  };

  const GetData = ( filter ) => {
    setLoading( true );
    getAllImages( {
      category_slug: slug,
      page: 1,
      per_page: imagePerPage,
      ...filter,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setPage( 1 );
        setTotal( response.data.data ? response?.data?.data?.total : 0 );
        setData( response.data.data ? response.data.data?.data : [] );
        setTotalPage( response.data.data ? response?.data?.data?.last_page : 0 );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
      }

      setLoading( false );
    } );
  };

  const handleLoadChange = ( type ) => {
    if ( type === "more" ) {
      setPage( page + 1 );
      GetPaginatedData( page + 1 );
    } else {
      setPage( page - 1 );
      GetPaginatedData( page - 1 );
    }
  };
  const handleOpenImage = () => {
    setOpenAddImage( true );
  };
  useEffect( () => {
    GetData();
  }, [ slug ] );

  return (
    <>
      { !loading ? (
        <>
          <TableUpsideSection
            search={ false }
            filterable={ false }
          // addButton={handleOpenImage}
          // addButtonTitle={`Add ${slug} image`}
          />

          <form onSubmit={ handleSubmit( handleSubmitForm ) }>

            { errors?.[ `${ slug }Id` ]?.message !== "" && (
              <FormControl>
                <FormHelperText>
                  { errors?.[ `${ slug }Id` ]?.message }
                </FormHelperText>
              </FormControl>

            ) }
            { data.length !== 0 ? (
              <>
                <div className="create-session-image-container margin-up-20">
                  <Controller
                    render={ ( {
                      field: { onChange, value },
                      fieldState: { error },
                    } ) =>
                      data.map( ( item ) => {
                        return (
                          <div className="image-container" key={ item.id }>
                            <img
                              src={ imagesURL + item.path }
                              width={ "120px" }
                              height={ slug === "background" ? "80px" : "120px" }
                              style={ {
                                border:
                                  item.id === getValues( `${ slug }Id` )?.id
                                    ? `5px solid ${ palette.primaryColorLight }`
                                    : `1px solid ${ palette.primaryColorLight }`,
                              } }
                              onClick={ () => {
                                setValue( `${ slug }Id`, item, {
                                  shouldValidate: true,
                                } );
                              } }
                            />
                          </div>
                        );
                      } )
                    }
                    name={ `${ slug }Id` }
                    control={ control }
                  />
                </div>
                <LoadMorePagination
                  page={ page }
                  loading={ loadMore }
                  pageTotal={ totalPage }
                  data={ data }
                  dataLeft={ data ? total - data.length : 0 }
                  handleLoadChange={ handleLoadChange }
                />
              </>
            ) : (
              <NoDataFound />
            ) }

            <div className="form-footer-style display-footer-right margin-up-20">
              <div className="form-footer-elements">
                <PrimaryButton
                  variant={ "outlined" }
                  text="Back"
                  onClick={ () => handleStep( { prev: true } ) }
                />

                <PrimaryButton
                  text={ activeStep === 1 ? "Next" : "Submit" }
                  type={ "submit" }
                  loading={ saveLoading }
                  disabled={ saveLoading ? true : false }
                />
              </div>
            </div>
          </form>
        </>
      ) : (
        <ImageSkeletonComponent
          count={ 10 }
          background={ slug === "background" ? true : false }
        />
      ) }

      { openAddImage && (
        <ImageHandle
          background={ slug === "background" ? true : false }
          onClose={ handleCloseHandleImage }
          handleResponse={ handleImageHandleResponse }
        />
      ) }
    </>
  );
}
export default SessionImage;
