//UTILITIES
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect, useContext } from "react";

//COMPONENT
import { Dialog, DialogContent } from "@mui/material";
import DialogCustomTitle from "../../../components/dialog-component/DialogCustomTitle";
import {
  FormDropdownList,
  FormSingleDashedUpload,
} from "../../../components/form-component/FormComponent";

import ResponseMessage from "../../../components/response-messages/ResponseMessage";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import { AlertContext } from "../../../context/AlertContext";
import { uploadImage } from "../../../core/apis/image";
import { getAllCategories } from "../../../core/apis/category";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function ImageHandle( props ) {
  //VARIABLES
  const { onClose, handleResponse, category_slug, background } = props;
  const { setAlert } = useContext( AlertContext );
  const [ message, setMessage ] = useState( "" );
  const [ categoryData, setCategoryData ] = useState( [] );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ loadingCategory, setLoadingCategory ] = useState( false );
  const HandleSchema = yup.object( {
    image: yup.mixed().required( "Field is required" ),
    category_slug: category_slug
      ? yup.string()
      : yup.object().nullable().required( "Field is required" ),
  } );
  //INITIATE USE FORM
  const { control, handleSubmit } = useForm( {
    defaultValues: {
      image: null,
      category_slug: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    let payload = new FormData();
    payload.append( "image", formData?.image );
    payload.append(
      "category_slug",
      !category_slug ? formData?.category_slug?.slug : category_slug
    );

    uploadImage( payload ).then( ( response ) => {
      if ( response.data?.success ) {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response?.data?.success ? false : true,
        } );
        handleResponse( response.data?.data );
      } else {
        setMessage( response.data ? response.data.message : ErrorMessage );
      }
      setSaveLoading( false );
    } );
  };

  const GetData = () => {
    setLoadingCategory( true );
    getAllCategories( {
      category_slug: category_slug
        ? category_slug
        : background
          ? "background"
          : "board",
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setCategoryData(
          response.data.data
            ? category_slug
              ? response.data.data
              : response?.data?.data?.[ 0 ]?.children
            : []
        );
      }
      setLoadingCategory( false );
    } );
  };

  useEffect( () => {
    GetData();
  }, [] );
  return (
    <>
      { " " }
      <Dialog disablebackdropclick="true" fullWidth open={ true }>
        <DialogCustomTitle title={ "Upload Image" } onClose={ () => onClose() } />

        <DialogContent>
          <form onSubmit={ handleSubmit( handleSubmitForm ) }>
            <div className="margin-up-20">
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormSingleDashedUpload
                    name="image"
                    cropped
                    type="Image"
                    aspectRatio={ background ? 16 / 9 : 1 / 1 }
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                      setMessage( "" );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="image"
                control={ control }
              />
            </div>
            { !category_slug && (
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormDropdownList
                    label="Category"
                    data={ categoryData }
                    loading={ loadingCategory }
                    name="category_slug"
                    placeholder={ "Enter category" }
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                      setMessage( "" );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="category_slug"
                control={ control }
              />
            ) }
            <ResponseMessage message={ message } />

            <div className="form-footer-style display-footer-right">
              <div className="form-footer-elements">
                <PrimaryButton
                  variant={ "outlined" }
                  text="Cancel"
                  onClick={ () => onClose() }
                />
                <PrimaryButton
                  text="Add"
                  type="submit"
                  loading={ saveLoading }
                  disabled={ saveLoading ? true : false }
                />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ImageHandle;
