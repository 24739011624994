//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
//COMPONENY
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
//API
import { AlertContext } from "../../../context/AlertContext";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import RowComponent from "../../../components/table-component/RowComponent";
import { TableCell } from "@mui/material";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import { exportUsers, getAllSubscriptionUsers } from "../../../core/apis/user";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function Users( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  let [ searchParams ] = useSearchParams();
  const { per_page, page, name, start_date, end_date, status } = groupParamsByKey( searchParams ) || {};
  const [ loading, setLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );


  const tableHeaders = [
    {
      name: "Name",
    },
    {
      name: "Email",
    },
    {
      name: "Plan",
    },
    {
      name: "Status",
    },
    {
      name: "Join Date",
    },
    {
      name: "Trial End Date",
    },

    {
      name: "Expiry Date",
    },
  ];
  //Functions
  const GetData = () => {

    setLoading( true );
    getAllSubscriptionUsers( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      status: searchParams.get( 'status' ) || null,
      search: searchParams.get( 'name' ) || "",
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) || "" },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" },
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setTotal( response.data.data?.users?.total );

        setTotalPage( response.data.data?.users?.last_page );
        setData( response.data.data ? response.data.data?.users?.data : [] );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };





  const handleExportFunctionality = () => {
    exportUsers().then( ( response ) => {
      if ( response?.status === 200 ) {
        const url = window.URL.createObjectURL( new Blob( [ response?.data ] ) );
        let filename = `subscribers.xlsx`;
        const link = document.createElement( "a" );
        link.href = url;
        link.setAttribute( "download", filename );
        // Append to html link element page
        document.body.appendChild( link );
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild( link );
      } else {
        setAlert( {
          text: "Failed to Download Document",
          error: true,
        } );
      }
    } );
  };




  useEffect( () => {
    GetData();
  }, [ name, page, per_page, start_date, end_date, status ] );



  return (
    <>
      <TitleComponent title="Subscribers" classNames="page-title main-title" />
      <TitleComponent
        title={ `Total Subscribers : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        type="users"
        filterByUserStatus
        filterByDateRange
        rangeOn={ "Joining Date" }
        placeholder={ "Search by name or email" }
        exportFunctionality={ total > 0 && handleExportFunctionality }

      />
      <div className="margin-up-20">
        <TableComponent
          headers={ tableHeaders }
          loading={ loading }
          data={ data }
          actions={ false }
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent
              row={ element }
              key={ element?.id }
              actions={ false }
              handleRowClick={ () =>
                navigate( `/subscribers/detail/${ element?.id }` )
              }
            >
              <TableCell className='long-tablecell-description'>
                { `${ element.first_name ? element.first_name : "" } ${ element?.last_name ? element?.last_name : ""
                  }` }
              </TableCell>
              <TableCell>{ element.email }</TableCell>

              <TableCell className='long-tablecell-description'>
                { element.latest_subscription
                  ? element.latest_subscription?.plan_name
                  : "-" }
              </TableCell>
              <TableCell>{ element.is_active ? "Active" : "Inactive" }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element?.registration_date } date />
              </TableCell>
              <TableCell>
                <DisplayDateTime
                  data={ element.latest_subscription?.trial_ends_at }
                  date
                />
              </TableCell>
              <TableCell>
                <DisplayDateTime
                  data={ element?.latest_subscription?.package_ends_at }
                  date
                />
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection
        total={ total }
        data={ data }
        totalPage={ totalPage }

      />
    </>
  );
}

export default Users;
