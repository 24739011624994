//UTILITIES
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListItemButton } from "@mui/material";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Collapse,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export function CustomizedListItem(props) {
  //VARIABLES
  const { index, drawer, item, length, handleCloseDrawer } = props;
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  //FUNCTIONS
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {!item.children && !item.route ? (
        <Tooltip title="Coming Soon" placement={"right-end"} arrow={true}>
          <ListItem
            disablePadding={true}
            selected={
              !item.children &&
              window.location.pathname.startsWith(`${item?.route}`)
            }
            key={`drawer${item.id}`}
          >
            <ListItemButton
              disabled={item.children ? false : item.route ? false : true}
              onClick={() => {
                if (item.children) {
                  handleOpen();
                } else {
                  navigate(item.route);
                  // handleCloseDrawer();
                }
              }}
            >
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  style: { whiteSpace: "normal" },
                }}
              />
              {item.children ? open ? <ExpandLess /> : <ExpandMore /> : null}{" "}
            </ListItemButton>
          </ListItem>
        </Tooltip>
      ) : (
        <ListItem
          disablePadding={true}
          selected={
            !item.children &&
            window.location.pathname.startsWith(`${item?.route}`)
          }
          key={`drawer${item.id}`}
        >
          <ListItemButton
            disabled={item.children ? false : item.route ? false : true}
            onClick={() => {
              if (item.children) {
                handleOpen();
              } else {
                navigate(item.route);
                // handleCloseDrawer();
              }
            }}
          >
            <ListItemIcon>{item?.icon}</ListItemIcon>

            <>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  style: { whiteSpace: "normal" },
                }}
              />
              {item.children ? open ? <ExpandLess /> : <ExpandMore /> : null}{" "}
            </>
          </ListItemButton>
        </ListItem>
      )}
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit key={item.id}>
          {item.children.map((sub_item, subindex) => {
            return (
              <div key={subindex}>
                <ListItemButton
                  disabled={sub_item.route ? false : true}
                  selected={window.location.pathname.includes(
                    `${sub_item.route}`
                  )}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    // handleMinimiseSidebar(false);
                    navigate(sub_item.route);
                    // handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>{sub_item.icon}</ListItemIcon>
                  <ListItemText
                    primary={sub_item.text}
                    primaryTypographyProps={{
                      style: { whiteSpace: "normal" },
                    }}
                  />
                </ListItemButton>
              </div>
            );
          })}
        </Collapse>
      )}
    </>
  );
}
export default CustomizedListItem;
