import { api } from "./main";

export const adminLogin = async ( payload ) => {
  return await api
    .post( "admin/v1/login", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const resetPassword = async ( payload ) => {
  return await api
    .post( "admin/v1/reset-password", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const resetPasswordForm = async ( payload ) => {
  return await api
    .post( "admin/v1/reset-password-form", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const changePassword = async ( payload ) => {
  return await api
    .post( "admin/v1/change-password", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const adminLogout = async ( payload ) => {
  return await api
    .get( "admin/v1/logout" )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const refreshToken = async ( payload ) => {
  return await api
    .get( "admin/v1/refresh-token", { params: { payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const isUserLoggedIn = async () => {
  return await api.get( 'admin/v1/is-loggedin' ).then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
}