//UTILITIES
import React from "react";

//COMPONENT
import { ThemeProvider } from "@mui/material/styles";
import AppRouter from "./core/routes/AppRouter";
import appTheme from "../src/assets/theme/MuiTheme";
import { CookiesProvider } from "react-cookie";
//CSS
import "./App.scss";

//SERVICES
import { AlertContextProvider } from "./context/AlertContext";
import { UserContextProvider } from "./context/UserContext";
import { SideBarContextProvider } from "./context/SideBarContext";


function App() {
  return (
    <>
      <ThemeProvider theme={ appTheme }>
        <SideBarContextProvider>
          <CookiesProvider>
            <UserContextProvider>
              <AlertContextProvider>
                <AppRouter />
              </AlertContextProvider>
            </UserContextProvider>
          </CookiesProvider>
        </SideBarContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
