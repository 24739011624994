//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContext";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import { UserContext } from "../../../context/UserContext";
//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import RowComponent from "../../../components/table-component/RowComponent";
import { TableCell } from "@mui/material";
import clipboardCopy from 'clipboard-copy';
//API
import { SceneURL } from "../../../core/apis/main";
import { deleteSession, getAllSessions, startSession } from "../../../core/apis/session";
import { ErrorMessage } from "../../../core/variables/MessageVariables";


export function Sessions( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const { user } = useContext( UserContext );
  const { setAlert } = useContext( AlertContext );

  const [ searchParams, setSearchParams ] = useSearchParams();
  const { per_page, page, name, start_date, end_date } = groupParamsByKey( searchParams ) || {};
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );
  const [ loading, setLoading ] = useState( false );
  const [ sessionInfo, setSessionInfo ] = useState( null );
  const [ openDeleteConfirmation, setOpenDeleteConfirmation ] = useState( false );
  const [ data, setData ] = useState( [] );


  const tableHeaders = [
    {
      name: "Session Name",
    },
    {
      name: "Date",
    },
    {
      name: "Facilitator",
    },
    {
      name: "Status",
    },
  ];
  //Functions

  const handleAddSession = () => {
    navigate( "/sessions/new" );
  };

  const handleDeleteResponse = ( value ) => {

    deleteSession( value.slug ).then( ( res ) => {
      if ( res.data?.success ) {
        if ( searchParams.get( 'page' ) == 1 ) {
          GetData();
        } else {
          setSearchParams( { page: 1, per_page: dataPerPage } );
        }
      }
      setAlert( {
        text: res.data ? res.data.message : ErrorMessage,
        error: res.data?.success ? false : true,
      } );
      return res;
    } );
    setOpenDeleteConfirmation( false );

  };

  const GetData = () => {
    setLoading( true );

    getAllSessions( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      name: searchParams.get( 'name' ) || "",
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) || "" },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" }, is_deleted: false
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setTotalPage( response?.data?.data?.sessions?.last_page );
        setData(
          response.data?.data.sessions ? response.data?.data?.sessions?.data : []
        );
        setTotal( response.data.data?.sessions?.total );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };

  const handleDelete = ( value ) => {
    setSessionInfo( value );
    setOpenDeleteConfirmation( true );
  };

  const handlePlay = ( value ) => {
    //we are calling this api to change the status of the session from active to running 
    //and resetting the expiry date of the session another new 24 hours
    startSession( value?.slug ).then( ( res ) => {
      if ( res?.data?.success ) {
        GetData();
        window.open( SceneURL + value?.slug + "/" + user?.id, "_blank" );
      }
      navigate( "/sessions" );
    } )

  };

  const handleCopy = ( value ) => {
    clipboardCopy( SceneURL + value?.slug ); //without portal to let it be a user link not admin
    setAlert( {
      text: "Link Copied",
      error: false,
    } );
  };








  useEffect( () => {
    GetData();

  }, [ per_page, page, name, start_date, end_date ] );



  return (
    <>
      <TitleComponent title={ `Sessions` } classNames="page-title main-title" />
      <TitleComponent
        title={ `Total Sessions : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        filterable
        filterByDateRange
        placeholder={ "Search by session name or facilitator" }
        addButtonTitle={ "New session" }
        addButton={ handleAddSession }
        search
        type="sessions"
      />
      <div className="margin-up-20">
        <TableComponent
          headers={ tableHeaders }
          loading={ loading }
          data={ data }
          actions
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent
              key={ element?.id }
              handlePlay={ () => handlePlay( element ) }
              handleCopy={ () => handleCopy( element ) }
              handleDelete={ () => handleDelete( element ) }
              row={ element }
            >
              <TableCell className='long-tablecell-description'>{ element.name }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element.created_at } date />
              </TableCell>
              <TableCell>
                { element.user?.first_name + " " + element?.user?.last_name }
              </TableCell>
              <TableCell>
                <div className='session-status'>{ element.status }</div>
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection
        total={ total }
        data={ data }
        totalPage={ totalPage }

      />

      { openDeleteConfirmation && (
        <NoticeConfirmation
          onClose={ () => setOpenDeleteConfirmation( false ) }
          onSuccess={ handleDeleteResponse }
          data={ sessionInfo }
        />
      ) }
    </>
  );
}

export default Sessions;
