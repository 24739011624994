//UTILITIES
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { AlertContext } from "../../../context/AlertContext";
//COMPONENT
import ResponseMessage from "../../../components/response-messages/ResponseMessage";
import TitleComponent from "../../../components/title-component/TitleComponent";
import SessionDescription from "./SessionDescription";
import SessionImage from "./SessionImage";
import SessionInvitation from "./SessionInvitation";
//API
import { createSession, startSession } from "../../../core/apis/session";
import { SceneURL } from "../../../core/apis/main";
import { ErrorMessage } from "../../../core/variables/MessageVariables";



export function SessionsHandle( props ) {
  //VARIABLES
  const { setAlert } = useContext( AlertContext );
  let navigate = useNavigate();

  const [ activeStep, setActiveStep ] = useState( 0 );
  const [ formValues, setFormValues ] = useState( null );
  const [ message, setMessage ] = useState( "" );
  const [ sessionDetail, setSessionDetail ] = useState( null );
  const [ openInvite, setOpenInvite ] = useState( false );

  const SubmitStep1 = ( formData ) => {
    setFormValues( {
      ...formValues,
      name: formData?.name,
      has_password: formData.has_password,
      ...( formData.has_password && { password: formData.password } ),
    } );
    handleStep( { next: true } );
  };

  const SubmitStep2 = async ( formData ) => {
    setFormValues( {
      ...formValues,
      backgroundId: formData?.backgroundId,
    } );
    handleStep( { next: true } );
    return true;
  };

  const handleSubmitForm = async ( formData ) => {
    let images_ids = [];
    images_ids.push( formValues?.backgroundId?.id );
    images_ids.push( formData?.boardId?.id );

    return createSession( {
      ...formValues,
      ...formData,
      images_id: images_ids,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
        //we are calling this api to change the status of the session from active to running 
        //and resetting the expiry date of the session another new 24 hours
        handleStartResponse( response.data.data );
        // setSessionDetail(response.data.data);
        // setOpenInvite(true);
      } else {
        setMessage( response.data ? response.data.message : ErrorMessage );
      }
      return response?.data?.success;
    } );
  };
  const handleInviteResponse = ( message ) => {
    setOpenInvite( false );
    navigate( "/sessions" );
    setAlert( {
      text: message,
      error: false,
    } );
  };

  const handleStartResponse = ( value ) => {
    window.open( SceneURL + value?.slug + "/" + value?.user_id, "_blank" );
    setOpenInvite( false );
    navigate( "/sessions" );
  };

  const handleStep = ( { prev, next } ) => {
    if ( next ) {
      setActiveStep( activeStep + 1 );
    } else {
      setActiveStep( activeStep - 1 );
    }
  };
  const steps = [
    {
      label: "Session Description",
      content: (
        <SessionDescription
          SubmitStep={ SubmitStep1 }
          activeStep={ activeStep }
          formValues={ formValues }
        />
      ),
    },
    {
      label: "Background Image",
      content: (
        <SessionImage
          slug="background"
          SubmitStep={ SubmitStep2 }
          activeStep={ activeStep }
          handleStep={ handleStep }
          formValues={ formValues }
        />
      ),
    },
    {
      label: "Board Image",
      content: (
        <SessionImage
          slug="board"
          SubmitStep={ handleSubmitForm }
          activeStep={ activeStep }
          formValues={ formValues }
          handleStep={ handleStep }
        />
      ),
    },
  ];
  return (
    <>
      <TitleComponent title="New Session" classNames="page-title main-title" />

      <div className="margin-up-20">
        <Stepper activeStep={ activeStep } orientation="vertical">
          { steps.map( ( step, index ) => (
            <Step key={ step.label }>
              <StepLabel className="step-label">{ step.label }</StepLabel>
              <StepContent>{ step?.content }</StepContent>
            </Step>
          ) ) }
        </Stepper>

        <ResponseMessage message={ message } />
      </div>
      { openInvite && (
        <SessionInvitation
          data={ sessionDetail }
          handleStartResponse={ handleStartResponse }
          onClose={ () => {
            setOpenInvite( false );
            navigate( "/sessions" );
          } }
          handleResponse={ handleInviteResponse }
        />
      ) }
    </>
  );
}
export default SessionsHandle;
