import { api } from "./main";

export const getAllSubscriptionUsers = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `admin/v1/users${ page }`, {
      params: { ...payload },
    } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const exportUsers = async ( payload ) => {
  return await api
    .get( `admin/v1/export-users`, {
      params: { ...payload },
      responseType: "blob",
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const getSelfUser = async ( payload ) => {
  return await api
    .get( `admin/v1/self-user`, {
      params: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const getUserById = async ( payload ) => {
  return await api
    .get( `admin/v1/user/${ payload }`, {
      params: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const extendUserTrial = async ( payload ) => {
  return await api
    .patch(
      `admin/v1/update-trial-expiry/${ payload?.plan_subscription_id }`,
      payload
    )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const updateUserDetail = async ( payload ) => {
  return await api
    .put( `admin/v1/update/${ payload?.id }`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
