//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//COMPONENT
import {
  IconsButton,
  PrimaryButton,
} from "../../../components/buttons/Buttons";
import {
  FormCheckBox,
  FormDropdownList,
  FormInput,
  FormNumberInput,
} from "../../../components/form-component/FormComponent";
import TitleComponent from "../../../components/title-component/TitleComponent";
import { AlertContext } from "../../../context/AlertContext";
import { getAllPlanItems } from "../../../core/apis/planItem";
import { PlusIcon, RemoveSVG } from "../../../assets/svg/SVG";
import { Skeleton } from "@mui/material";
//API
import {
  createPlan,
  getAllCurrencies,
  getPlanBySlug,
  updatePlan,
} from "../../../core/apis/plan";
import { nameRequiredLimited } from "../../../core/validators/form-validators";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

const HandleSchema = yup.object( {
  name: nameRequiredLimited( true, 255 ),
  description: nameRequiredLimited( true, 255 ),
  trial_duration: yup
    .number()
    .nullable()
    .min( 0, "Field must be at least 0" )
    .required( "Field is required" ),
  trial_duration_type: yup.object().nullable().required( "Field is required" ),
  items: yup.array().of(
    yup.object().shape( {
      id: yup.object(),
      is_unlimited: yup.boolean(),
      value: yup
        .number()
        .nullable()
        .when( "is_unlimited", {
          is: true,
          then: yup.number().nullable(),
          otherwise: yup
            .number()
            .min( 0, "Field must be at least 0" )
            .nullable()
            .required( "Field is required" ),
        } ),
    } )
  ),
  prices: yup.array().of(
    yup.object().shape( {
      currency_id: yup.object().nullable().required( "Field is required" ),
      price: yup
        .number()
        .nullable()
        .required( "Field is required" )
        .positive( "Field should be positive" ),
      package_duration: yup
        .number()
        .nullable()
        .positive( "Field should be positive" )
        .required( "Field is required" ),
      package_duration_type: yup
        .object()
        .nullable()
        .required( "Field is required" ),
      description: nameRequiredLimited( false, 50 ),
    } )
  ),
} );

export function SubscriptionHandle( props ) {
  const { type } = props;

  let { slug } = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ currencies, setCurrencies ] = useState( [] );

  const durationTypes = [
    {
      name: "Day",
      slug: "day",
      id: 1,
    },
    {
      name: "Month",
      slug: "month",
      id: 2,
    },
    {
      name: "Year",
      slug: "year",
      id: 3,
    },
  ];

  const [ loading, setLoading ] = useState( false );

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );

  const {
    fields: pricesFields,
    remove: pricesRemove,
    append: pricesAppend,
  } = useFieldArray( { control, name: "prices" } );

  //FUNCTIONS

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    if ( type === "edit" ) {
      updatePlan( {
        ...formData,
        items: formData?.items?.map( ( el ) => ( {
          ...el,
          id: el?.id?.id,
          value: el?.value === null ? 0 : el?.value,
        } ) ),
        trial_duration_type: formData?.trial_duration_type?.slug,
        prices: formData?.prices?.map( ( val ) => ( {
          ...val,
          currency_id: val?.currency_id?.id,
          package_duration_type: val?.package_duration_type?.slug,
        } ) ),
        slug: slug,
      } ).then( ( response ) => {
        if ( response.data?.success ) {
          navigate( "/subscriptions", {
            state: { message: response.data.message },
          } );
        }
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
        setSaveLoading( false );
      } );
    } else {
      createPlan( {
        ...formData,
        items: formData?.items?.map( ( el ) => ( {
          ...el,
          id: el?.id?.id,
          value: el?.value === null ? 0 : el?.value,
        } ) ),
        trial_duration_type: formData?.trial_duration_type?.slug,
        prices: formData?.prices?.map( ( val ) => ( {
          ...val,
          currency_id: val?.currency_id?.id,
          package_duration_type: val?.package_duration_type?.slug,
        } ) ),
      } ).then( ( response ) => {
        if ( response.data?.success ) {
          navigate( "/subscriptions" );
        }
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
        setSaveLoading( false );
      } );
    }
  };

  const GetAllCurrencies = async () => {
    return getAllCurrencies().then( ( response ) => {
      if ( response?.data?.success ) {
        setCurrencies( response?.data?.data ? response?.data?.data : [] );
      }
      return response?.data?.data || [];
    } );
  };
  const GetData = async () => {
    let plansItems = await GetPlanItemsData();
    getPlanBySlug( slug ).then( ( response ) => {
      if ( response.data?.success ) {
        let data = response.data.data;

        reset( {
          name: data?.name,
          description: data?.description,
          trial_duration: data?.trial_duration,
          trial_duration_type: durationTypes?.find(
            ( el ) => el?.slug === data?.trial_duration_type
          ),
          prices: data?.plan_price?.map( ( el ) => ( {
            plan_price_id: el?.id,
            currency_id: el?.currency,
            description: el?.description,
            price: el?.price,
            package_duration: el?.package_duration,
            package_duration_type: durationTypes?.find(
              ( val ) => val?.slug === el?.package_duration_type
            ),
          } ) ),
          items: plansItems?.map( ( el ) => {
            let element = data?.items?.find(
              ( val ) => val?.slug === el?.id?.slug
            );

            return {
              ...el,
              is_unlimited: element?.pivot?.is_unlimited === 1 ? true : false,
              value:
                element?.pivot?.is_unlimited === 1
                  ? null
                  : element?.pivot?.value,
            };
          } ),
        } );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };
  const handleAddFieldOption = () => {
    pricesAppend( {
      plan_price_id: null,
      package_duration: null,
      package_duration_type: null,
      price: null,
      currency_id: currencies?.find( ( el ) => el?.slug === "euro" ),
      description: "",
    } );
  };

  const handleRemoveFieldOption = ( i ) => {
    pricesRemove( i );
  };

  const GetPlanItemsData = async () => {
    let currenciesData = await GetAllCurrencies();
    let all_plans_items = [];
    //staticly 10 because no more then 20
    return getAllPlanItems( { page: 1, per_page: 20 } ).then( ( response ) => {
      if ( response?.data?.success ) {
        all_plans_items = response?.data?.data?.data?.map( ( val, index ) => ( {
          is_unlimited:
            val?.slug === "created-sessions" ||
              val?.slug === "uploaded-images"
              ? false
              : true,
          value:
            val?.slug === "created-sessions" ||
              val?.slug === "uploaded-images"
              ? null
              : 0,
          id: val,
          index: index,
        } ) );

        if ( type !== "edit" ) {
          reset( {
            name: "",
            description: "",
            trial_duration: null,
            trial_duration_type: { name: "Day", id: 1, slug: "day" },
            items: all_plans_items,
            prices: [
              {
                plan_price_id: null,
                price: null,
                package_duration: null,
                package_duration_type: { name: "Month", id: 2, slug: "month" },
                currency_id: currenciesData?.find( ( el ) => el?.slug === "euro" ),
                description: "",
              },
            ],
          } );
          setLoading( false );
        }
      }
      return all_plans_items;
    } );
  };

  useEffect( () => {
    setLoading( true );
    if ( type === "edit" ) {
      GetData();
    } else {
      GetPlanItemsData();
    }
  }, [] );
  return (
    <>
      <TitleComponent
        title={ type === "edit" ? "Edit Subscription" : "Add Subscription" }
        classNames="page-title main-title"
      />

      { !loading ? (
        <form
          onSubmit={ handleSubmit( handleSubmitForm ) }
          className="subscription-handle-component"
        >
          <div className="section">
            <div className="form-double-inputs-children">
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    required
                    name="name"
                    label={ "Subscription Name" }
                    placeholder={ "Enter name" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="name"
                control={ control }
              />
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    required
                    name="description"
                    label={ "Description" }
                    placeholder={ "Enter description" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="description"
                control={ control }
              />
            </div>
            <div className="subscription-items">
              { getValues( "items" )
                ?.filter(
                  ( el ) =>
                    el?.id?.slug === "created-sessions" ||
                    el?.id?.slug === "uploaded-images"
                )
                ?.map( ( val ) => (
                  <div key={ `${ val?.index }-${ getValues( "items" )?.length }` } className="plan-items-space">
                    <div className="value-space">
                      <Controller
                        render={ ( {
                          field: { onChange, value },
                          fieldState: { error },
                        } ) => (
                          <FormNumberInput
                            required={
                              getValues( `items.${ val?.index }.is_unlimited` )
                                ? false
                                : true
                            }
                            disabled={
                              getValues( `items.${ val?.index }.is_unlimited` ) ===
                                true
                                ? true
                                : false
                            }
                            name={ `items.${ val?.index }.value` }
                            label={ `Nb ${ val?.id?.slug === "uploaded-images"
                              ? "of images to upload"
                              : "of sessions to create"
                              } ` }
                            placeholder={ "Enter number" }
                            value={ value === null ? "" : value }
                            onChange={ ( value ) => {
                              onChange( value === "" ? null : value );
                            } }
                            helperText={ error?.message }
                          />
                        ) }
                        name={ `items.${ val?.index }.value` }
                        control={ control }
                      />
                    </div>
                    <div className="unlimited-space">
                      <Controller
                        render={ ( {
                          field: { onChange, value },
                          fieldState: { error },
                        } ) => (
                          <FormCheckBox
                            required
                            name={ `items.${ val?.index }.is_unlimited` }
                            label={ "Unlimited" }
                            value={ value }
                            onChange={ ( e ) => {
                              setValue( `items.${ val?.index }.is_unlimited`, e, {
                                shouldValidate: true,
                              } );

                              setValue( `items.${ val?.index }.value`, null, {
                                shouldValidate: true,
                              } );
                            } }
                            helperText={ error?.message }
                          />
                        ) }
                        name={ `items.${ val?.index }.is_unlimited` }
                        control={ control }
                      />
                    </div>

                  </div>
                ) ) }
            </div>
          </div>
          <div className="section">
            <div className="section-title">{ "Free Trial" }</div>
            <div className="form-double-inputs-children">
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormNumberInput
                    required
                    name="trial_duration"
                    label={ "Duration" }
                    placeholder={ "Enter duration" }
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="trial_duration"
                control={ control }
              />
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormDropdownList
                    required
                    data={ durationTypes }
                    name="trial_duration_type"
                    label={ "Duration Type" }
                    placeholder={ "Enter duration type" }
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="trial_duration_type"
                control={ control }
              />
            </div>
          </div>
          <div className="section">
            <div className="section-title">
              { "Packages" }
            </div>
            <div className="packages-inputs-dynamic">
              { pricesFields?.map( ( element, priceIndex ) => (
                <div
                  className="packages-inputs"
                  key={ `${ priceIndex }-${ pricesFields?.length }` }
                >
                  <div className="info">
                    <div className="info-inputs">
                      <Controller
                        render={ ( {
                          field: { onChange, value },
                          fieldState: { error },
                        } ) => (
                          <FormNumberInput
                            required
                            name={ `prices.${ priceIndex }.package_duration` }
                            label={ priceIndex === 0 ? "Duration" : "" }
                            placeholder={ "Enter duration" }
                            value={ value }
                            onChange={ ( value ) => {
                              onChange( value === "" ? null : value );
                            } }
                            helperText={ error?.message }
                          />
                        ) }
                        name={ `prices.${ priceIndex }.package_duration` }
                        control={ control }
                      />
                    </div>
                    <div className="info-inputs">
                      <Controller
                        render={ ( {
                          field: { onChange, value },
                          fieldState: { error },
                        } ) => (
                          <FormDropdownList
                            required
                            data={ durationTypes }
                            name={ `prices.${ priceIndex }.package_duration_type` }
                            label={ priceIndex === 0 ? "Duration Type" : "" }
                            disabledOptions={ getValues( "prices" )?.map( ( el ) => {
                              if ( el?.package_duration_type ) {
                                return el?.package_duration_type?.slug;
                              }
                            } ) }
                            placeholder={ "Enter duration type" }
                            value={ value }
                            onChange={ ( value ) => {
                              onChange( value );
                            } }
                            helperText={ error?.message }
                          />
                        ) }
                        name={ `prices.${ priceIndex }.package_duration_type` }
                        control={ control }
                      />
                    </div>
                    <div className="info-inputs">

                      <Controller
                        render={ ( {
                          field: { onChange, value },
                          fieldState: { error },
                        } ) => (
                          <FormNumberInput
                            required
                            price={
                              getValues( `prices.${ priceIndex }.currency_id` )
                                ?.symbol
                            }
                            name={ `prices.${ priceIndex }.price` }
                            label={ priceIndex === 0 ? "Price" : "" }
                            placeholder={ "Enter price" }
                            value={ value }
                            onChange={ ( value ) => {
                              onChange( value === "" ? null : value );
                            } }
                            helperText={ error?.message }
                          />
                        ) }
                        name={ `prices.${ priceIndex }.price` }
                        control={ control }
                      />
                    </div>
                  </div>
                  <div className="package-description">
                    <Controller
                      render={ ( {
                        field: { onChange, value },
                        fieldState: { error },
                      } ) => (
                        <FormInput
                          price={
                            getValues( `prices.${ priceIndex }.description` )
                              ?.symbol
                          }
                          name={ `prices.${ priceIndex }.description` }
                          label={ priceIndex === 0 ? "Description" : "" }
                          placeholder={ "Enter description" }
                          value={ value }
                          onChange={ ( value ) => {
                            onChange( value );
                          } }
                          helperText={ error?.message }
                        />
                      ) }
                      name={ `prices.${ priceIndex }.description` }
                      control={ control }
                    />
                  </div>
                  { pricesFields.length > 1 && !element?.plan_price_id && priceIndex !== 0 ? (
                    <div className="dynamic-field-option-section">
                      <div>
                        <IconsButton
                          icon={ <RemoveSVG /> }
                          onClick={ () => handleRemoveFieldOption( priceIndex ) }
                        />
                      </div>
                    </div>
                  ) : null }
                  { priceIndex === 0 && (
                    <div className="dynamic-field-option-section">
                      <div>
                        { pricesFields.length < durationTypes?.length && (
                          <IconsButton
                            icon={ <PlusIcon /> }
                            onClick={ handleAddFieldOption }
                          /> ) }
                      </div>
                    </div> ) }
                </div>
              ) ) }


            </div>
          </div>
          <div className="form-footer-style display-footer-right">
            <div className="form-footer-elements">
              <PrimaryButton
                variant={ "outlined" }
                text="Cancel"
                onClick={ () => navigate( -1 ) }
              />
              <PrimaryButton
                text={ type === "edit" ? "Edit" : "Add" }
                type="submit"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </div>
        </form >
      ) : (
        <Skeleton />
      )
      }
    </>
  );
}

export default SubscriptionHandle;
