//ALL FORM VALIDATION FILE
import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
//REGEX DEFINITION
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const stringRegex = new RegExp(/\S/);
const passRegex = new RegExp(/^.{6,}$/);

export const phoneNumberSchema = yup
  .string()
  .test("invalid", "Invalid Format", (val) => val && isValidPhoneNumber(val))
  .nullable()
  .required("Field is required");

export const emailSchema = yup
  .string()
  .email("Invalid Format")
  .nullable()
  .required("Field is required");

export const passwordSchema = yup
  .string("Invalid Field")
  .nullable()
  .matches(passRegex, "Field should be of minimum 6 characters length")
  .required("Field is required");

export const nameRequiredLimited = (required, char) => {
  let schema = yup
    .string()
    .nullable()
    .test(
      "len",
      `Field should be of ${char} characters max`,
      (val) =>
        !val || val?.length === 0 || (val?.length !== 0 && val.length < char)
    );

  if (required) {
    schema = schema
      .required("Field is required")
      .test("len", "Field is required", (val) => val && val?.trim().length > 0);
  }
  return schema;
};
