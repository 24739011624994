//UTILTIIES
import { Card, CardContent } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContext";
//COMPONENT
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import palette from "../../../assets/theme/color.scss";
import LoaderComponent, {
  CircularDashboardSkeleton,
  DashboardSkeleton,
} from "../../../components/loader-component/LoaderComponent";
import NoDataFound from "../../../components/response-messages/NoDataFound";
import TitleComponent from "../../../components/title-component/TitleComponent";
//API
import {
  getDashboardSessions,
  getDashboardSubscribers,
  getInvoicesStatistics,
} from "../../../core/apis/dashboard";

//CSS
import "./Dashboard.scss";
import { FormCalendarInput } from "../../../components/form-component/FormComponent";
import { useSearchParams } from "react-router-dom";
import { objectCleaner } from "../../../core/functions/useDebounce";
import { DoughnutNoDataFound } from "../../../assets/svg/DashboardSVG";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function Dashboard( props ) {
  const { setAlert } = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ filter, setFilter ] = useState( {
    start_date:
      searchParams.get( "start_date" ) ||
      moment( new Date( new Date() - 6 * 24 * 60 * 60 * 1000 ) ).format(
        "YYYY-MM-DD"
      ),
    end_date:
      searchParams.get( "end_date" ) || moment( new Date() ).format( "YYYY-MM-DD" ),
  } );

  const [ loadingDateChart, setLoadingDateChart ] = useState( false );
  const [ loadingStatusChart, setLoadingStatusChart ] = useState( false );
  const [ loadingInvoicesChart, setLoadingInvoicesChart ] = useState( false );

  const [ dateChartData, setDateChartData ] = useState( null );
  const [ invoiceChartData, setInvoiceChartData ] = useState( null );
  const [ statusChartData, setStatusChartData ] = useState( null );

  //FUNCTIONS

  const handleFilterDate = ( value ) => {
    let newFilters = {
      start_date: value
        ? moment( new Date( value - 6 * 24 * 60 * 60 * 1000 ) ).format( "YYYY-MM-DD" )
        : moment( new Date( new Date() - 6 * 24 * 60 * 60 * 1000 ) ).format(
          "YYYY-MM-DD"
        ),
      end_date: value
        ? moment( value ).format( "YYYY-MM-DD" )
        : moment( new Date() ).format( "YYYY-MM-DD" ),
    };


    setSearchParams( objectCleaner( newFilters ) );
  };

  const GetSessionsData = ( filter ) => {
    setLoadingDateChart( true );

    getDashboardSessions( { ...filter } ).then( ( response ) => {
      if ( response.data?.success ) {
        if ( response.data?.data ) {
          setDateChartData( {
            range_dates: [ filter?.start_date, filter?.end_date ],
            labels: response.data.data
              ? response.data.data?.map( ( d ) => d?.day?.substr( 0, 3 ) )
              : [],

            datasets: [
              {
                data: response.data.data?.map( ( d ) => d?.number_of_sessions ),
                backgroundColor: [ palette.primaryColorLight ],
              },
            ],
          } );
        }
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
      }
      setLoadingDateChart( false );
    } );
  };

  const GetInvoicesCouponData = () => {
    setLoadingInvoicesChart( true );
    getInvoicesStatistics().then( ( res ) => {
      if ( res?.data?.success ) {
        if ( res.data?.data ) {
          setInvoiceChartData( {
            labels: res?.data?.data ? Object.keys( res?.data?.data ) : [],

            datasets: [
              {
                data: res?.data?.data ? Object.values( res?.data?.data ) : [],
                backgroundColor: [ palette.primaryColorLight ],
              },
            ],
          } );
        }
      } else {
        setAlert( {
          text: res.data ? res.data.message : ErrorMessage,
          error: res.data?.success ? false : true,
        } );
      }
      setLoadingInvoicesChart( false );
    } );
  };
  const GetSubscribersData = () => {
    setLoadingStatusChart( true );
    getDashboardSubscribers().then( ( response ) => {
      if ( response.data?.success ) {
        if ( response.data?.data ) {
          setStatusChartData( {
            labels: Object.keys( response?.data?.data?.[ 0 ] ),
            datasets: [
              {
                data: Object.values( response?.data?.data?.[ 0 ] ),
                backgroundColor: [ palette.primaryColor, palette.secondaryColor ],
              },
            ],
          } );
        }
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
      }
      setLoadingStatusChart( false );
    } );
  };

  useEffect( () => {
    GetInvoicesCouponData();
    GetSubscribersData();
  }, [] );

  useEffect( () => {
    GetSessionsData( filter );
  }, [ filter ] );

  useEffect( () => {
    setFilter( {
      start_date: searchParams.get( "start_date" )
        ? searchParams.get( "start_date" )
        : moment( new Date( new Date() - 6 * 24 * 60 * 60 * 1000 ) ).format(
          "YYYY-MM-DD"
        ),
      end_date: searchParams.get( "end_date" )
        ? searchParams.get( "end_date" )
        : moment( new Date() ).format( "YYYY-MM-DD" ),
    } );
  }, [ searchParams ] );

  return (
    <>
      <TitleComponent title={ "Dashboard" } classNames="page-title main-title" />
      <div className="first-row-container">
        <div className="chart-display">
          <Card style={ { height: "100%" } }>
            <CardContent>
              <div className="intro">
                <>
                  <TitleComponent
                    title={ "Sessions" }
                    classNames="page-title sub-title"
                  />
                  <div className="dashboard-date-picker">
                    <FormCalendarInput
                      value={
                        filter.end_date
                          ? new Date( filter?.end_date )
                          : new Date()
                      }
                      onChange={ ( value ) => handleFilterDate( value ) }
                      disabledAfter={ new Date() }
                    />
                  </div>

                  <div className="dashboard-chart">
                    { !loadingDateChart ? (
                      dateChartData ? (
                        <Chart
                          type="bar"
                          data={ dateChartData }
                          options={ {
                            responsive: true,
                            maintainAspectRatio: false,
                            barThickness: 10,
                            height: 200,

                            // aspectRatio: 1,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          } }
                        />
                      ) : (
                        <NoDataFound />
                      )
                    ) : (
                      <DashboardSkeleton />
                    ) }
                  </div>
                  { !loadingDateChart && (
                    <div className="range-date-chosen">
                      { filter?.start_date
                        ? moment( filter?.start_date ).format( "DD-MM-YYYY" )
                        : "" }
                      { " - " }
                      { filter?.end_date
                        ? moment( filter?.end_date ).format( "DD-MM-YYYY" )
                        : "" }
                    </div>
                  ) }
                </>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="chart-display">
          <Card style={ { height: "100%" } }>
            <CardContent>
              <div className="intro">
                <>
                  <TitleComponent
                    title={ "Subscribers" }
                    classNames="page-title sub-title"
                  />
                  <div className="dashboard-chart donuts-chart">
                    { !loadingStatusChart ? (
                      statusChartData ? (
                        <Chart
                          type={ "doughnut" }
                          data={ statusChartData }
                          options={ {
                            width: "100%",
                            height: "100%",
                            responsive: true,
                            radius: "100%",
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: "bottom",
                                display:
                                  statusChartData?.labels?.length !== 0
                                    ? true
                                    : false,
                              },
                            },
                          } }
                        />
                      ) : (
                        <DoughnutNoDataFound />
                      )
                    ) : (
                      <CircularDashboardSkeleton />
                    ) }
                  </div>{ " " }
                </>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="second-row-container">
        <div className="chart-display">
          <Card style={ { height: "100%" } }>
            <CardContent>
              <div className="intro">
                <>
                  <TitleComponent
                    title={ "Invoices" }
                    classNames="page-title sub-title"
                  />

                  <div className="dashboard-chart">
                    { !loadingInvoicesChart ? (
                      invoiceChartData ? (
                        <Chart
                          type="bar"
                          data={ invoiceChartData }
                          options={ {
                            indexAxis: "y",
                            responsive: true,
                            maintainAspectRatio: false,
                            barThickness: 10,
                            height: 200,

                            // aspectRatio: 1,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          } }
                        />
                      ) : (
                        <NoDataFound />
                      )
                    ) : (
                      <DashboardSkeleton />
                    ) }
                  </div>
                </>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
