//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
//COMPONENY
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
//API
import RowComponent from "../../../components/table-component/RowComponent";
import { TableCell } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";
import {
  deleteCoupon,
  exportCoupons,
  getAllCoupons,
} from "../../../core/apis/coupon";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function Coupons( props ) {
  //VARIABLES
  let navigate = useNavigate();

  const { setAlert } = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { per_page, page, name, start_date, end_date, is_deleted } = groupParamsByKey( searchParams ) || {};
  const [ couponDetail, setCouponDetail ] = useState( null );
  const [ openDelete, setOpenDelete ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );


  const tableHeaders = [
    {
      name: "Code",
    },
    {
      name: "Name",
    },

    {
      name: "Discount",
      align: "center",
    },
    {
      name: "Usage",
    },
    {
      name: "Created Date",
    },

    {
      name: "Expiry Date",
    },
  ];
  //Functions

  const handleDeleteResponse = ( row ) => {

    deleteCoupon( row?.id ).then( ( res ) => {
      if ( res?.data?.success ) {
        if ( +searchParams.get( 'page' ) === 1 ) {
          GetData();
        } else {
          setSearchParams( { page: 1, per_page: dataPerPage } );
        }
      }
      setAlert( {
        text: res.data ? res.data.message : ErrorMessage,
        error: res.data?.success ? false : true,
      } );
      setOpenDelete( false );
      return res;
    } )


  }

  const handleExportFunctionality = () => {
    exportCoupons( {
      start_date: searchParams.get( 'start_date' ),
      end_date: searchParams.get( 'end_date' ),
    } ).then( ( response ) => {
      if ( response?.status === 200 ) {
        const url = window.URL.createObjectURL( new Blob( [ response?.data ] ) );
        let filename = `coupons.xlsx`;
        const link = document.createElement( "a" );
        link.href = url;
        link.setAttribute( "download", filename );
        // Append to html link element page
        document.body.appendChild( link );
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild( link );
      } else {
        setAlert( {
          text: "Failed to download",
          error: true,
        } );
      }
    } );
  };

  const GetData = () => {
    setLoading( true );
    getAllCoupons( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      is_deleted: searchParams.get( 'is_deleted' ) || false,
      name: searchParams.get( 'name' ) || "",
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" },
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setTotal( response?.data.data?.coupons?.total );
        setTotalPage( response?.data.data?.coupons?.last_page );
        setData( response?.data.data ? response.data.data?.coupons?.data : [] );
      } else {
        setAlert( {
          text: response?.data ? response.data.message : ErrorMessage,
          error: response?.data?.success ? false : true,
        } );
      }
      setLoading( false );
    } )
      ;
  };



  const handleAddCoupon = () => {
    navigate( "/coupons/new" );
  };


  useEffect( () => {
    GetData();
  }, [ per_page, page, name, start_date, end_date, is_deleted ] );

  return (
    <>
      <TitleComponent title={ `Coupons` } classNames="page-title main-title" />
      <TitleComponent
        title={ `Total Coupons : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        search
        addButtonTitle={ "New coupon" }
        addButton={ handleAddCoupon }
        exportFunctionality={ total > 0 && handleExportFunctionality }
        placeholder={ "Search by coupon name" }
        filterByDateRange
        filterByDeleted

      />
      <div className="margin-up-20">
        <TableComponent
          headers={ tableHeaders }
          loading={ loading }
          data={ data }
          actions
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent
              key={ element?.id }
              row={ element }
              handleEdit={
                !element?.deleted_at
                  ? () => navigate( `/coupons/edit/${ element?.id }` )
                  : null
              }
              handleRowClick={ () => navigate( `/coupon/detail/${ element?.id }` ) }
              handleDelete={
                !element?.deleted_at
                  ? () => {
                    setCouponDetail( element );
                    setOpenDelete( true );
                  }
                  : null
              }
            >
              <TableCell>{ element.code }</TableCell>
              <TableCell className='long-tablecell-description'>{ element.name }</TableCell>
              <TableCell align="center">
                <div className="coupon-amount-display">
                  <div>{ element.value }</div>
                  <div>{ " % " }</div>
                </div>
              </TableCell>
              <TableCell>{ element?.subscription_count }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element.created_at } date />
              </TableCell>
              <TableCell>
                <DisplayDateTime data={ element.expiry_date } date />
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection
        total={ total }
        data={ data }

        totalPage={ totalPage }

      />
      { openDelete && (
        <NoticeConfirmation
          onClose={ () => setOpenDelete( false ) }
          onSuccess={ handleDeleteResponse }
          data={ couponDetail }
        />
      ) }
    </>
  );
}

export default Coupons;
