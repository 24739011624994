//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../context/AlertContext";
//COMPONENT
import { arrayMove } from "@dnd-kit/sortable";
import { TableCell } from "@mui/material";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import TitleComponent from "../../../components/title-component/TitleComponent";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import TableComponent from "../../../components/table-component/TableComponent";
import RowComponent from "../../../components/table-component/RowComponent";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";

//API
import {
  deletePlan,
  getAllPlans,
  updatePlanOrder,
} from "../../../core/apis/plan";
//CSS
import "./Subscriptions.scss";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function Subscriptions( props ) {
  //VARIABLES
  const { setAlert } = useContext( AlertContext );
  let navigate = useNavigate();
  let [ searchParams, setSearchParams ] = useSearchParams();
  const { per_page, page, name } = groupParamsByKey( searchParams ) || {};
  const [ canDrag, setCanDrag ] = useState( false );
  const [ openDelete, setOpenDelete ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ loadingDrag, setLoadingDrag ] = useState( false );
  const [ data, setData ] = useState( [] );
  const [ selectedPlan, setSelectedPlan ] = useState( null );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );
  const [ filter, setFilter ] = useState( {
    name: searchParams.get( "name" ) || "",
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: +searchParams.get( "page" ) || 1,
  } );

  const tableHeaders = [
    {
      name: "Name",
    },
    {
      name: "Created At",
    },
  ];

  //Functions
  // Handle drag movement and setting array values
  // Handle drag movement and setting array values
  const handleDragEnd = ( { active, over } ) => {
    if ( active.id !== over.id ) {
      const dragId = data.find( ( item ) => item.id === active.id );
      const dropId = data.find( ( item ) => item.id === over.id );

      if ( dragId && dropId ) {
        const oldIndex = data.indexOf( dragId );
        const newIndex = data.indexOf( dropId );

        setData( ( items ) => {
          return arrayMove( items, oldIndex, newIndex );
        } );
      }
    }
  };

  const handleSaveDrag = () => {
    setLoadingDrag( true );
    updatePlanOrder( { plans: data?.map( ( el ) => ( { slug: el.slug } ) ) } ).then(
      ( res ) => {
        if ( res?.data?.success ) {
          let newFilters = {
            ...filter,
            name: "",
            page: 1,
            per_page: dataPerPage,
          };
          setCanDrag( false, () => {
            setSearchParams( objectCleaner( newFilters ) );
          } );
        } else {
          setCanDrag( false );
        }
        setAlert( {
          text: res.data ? res.data.message : ErrorMessage,
          error: res?.data?.success ? false : true,
        } );
        setLoadingDrag( false );
      }
    );
  };
  const handleSetDrag = () => {
    let newFilters = {
      ...filter,
      name: "",
      page: 1,
      per_page: 50, //staticly
    };
    setSearchParams( objectCleaner( newFilters ) );
    setCanDrag( true );
  };



  const GetData = () => {
    setLoading( true );

    getAllPlans( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      name: searchParams.get( 'name' ) || "",
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setTotal( response.data.data ? response?.data?.data?.total : 0 );
        setTotalPage( response.data.data ? response?.data?.data?.last_page : 0 );
        setData( response.data.data ? response?.data?.data?.data : [] );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: !response?.data?.success,
        } );
      }
      setLoading( false );
    } );
  };


  //Functions
  useEffect( () => {
    GetData();
  }, [ name, per_page, page ] );


  const handleEdit = ( plan ) => {
    navigate( `/subscriptions/edit/${ plan.slug }` );
  };

  const handleDelete = ( plan ) => {
    setSelectedPlan( plan );
    setOpenDelete( true );
  };

  const handleAddButton = () => {
    navigate( "/subscriptions/new" );
  };
  const handleDeleteResponse = ( data ) => {

    deletePlan( data ).then( ( res ) => {
      if ( res?.data?.success ) {
        if ( filter.page === 1 ) {
          GetData( filter );
        } else {
          setSearchParams( { page: 1, per_page: dataPerPage } );
        }
      }
      setAlert( {
        text: res.data ? res.data.message : ErrorMessage,
        error: !res?.data?.success,
      } );
      setOpenDelete( false );
      return res;
    } );

  };

  return (
    <>
      <TitleComponent
        title={ `Subscriptions` }
        classNames="page-title main-title"
      />
      <TitleComponent
        title={ `Total Subscriptions : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        filterable={ false }
        addButtonTitle={ "New subscription" }
        addButton={ handleAddButton }
        placeholder={ "Search by name" }
        handleCardView={ () => navigate( "/subscriptions/cards-view" ) }
        handleDrag={ canDrag ? () => handleSaveDrag() : () => handleSetDrag() }
        dragChecked={ canDrag }
        loadingDrag={ loadingDrag }
        handleS
      />
      <div className="margin-up-20">
        <TableComponent
          handleDragEnd={ handleDragEnd }
          headers={ tableHeaders }
          loading={ loading }
          canDrag={ canDrag }
          data={ data }
        >
          { data?.map( ( element, index ) => (
            <RowComponent
              handleEdit={ canDrag ? null : handleEdit }
              handleDelete={
                canDrag || element?.subscriptions_count !== 0
                  ? null
                  : handleDelete
              }
              row={ element }
              canDrag={ canDrag }
              data={ data }
              key={ element?.id }
              index={ index }
            >
              <TableCell className='long-tablecell-description'>{ element?.name }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element?.created_at } date />
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
        { !canDrag && (
          <TablePaginationSection
            total={ total }
            data={ data }
            totalPage={ totalPage }

          />
        ) }
      </div>
      { openDelete && (
        <NoticeConfirmation
          onSuccess={ handleDeleteResponse }
          onClose={ () => setOpenDelete( false ) }
          data={ selectedPlan }
        />
      ) }
    </>
  );
}

export default Subscriptions;
