//UTILITIES
import React from "react";
import PropTypes from "prop-types";
//COMPONENT
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Skeleton,
} from "@mui/material";

//CSS
import "./LoaderComponent.scss";
import { dataPerPage } from "../../core/variables/CoreVariables";

export function LinearLoaderComponent( props ) {
  return (
    <>
      <Grid spacing={ 1 } container>
        <Grid xs item>
          <LinearProgress title="linear" />
        </Grid>
      </Grid>
    </>
  );
}

export function LoaderComponent( props ) {
  const { normal } = props;
  return (
    <div className={ `loader-component ${ normal ? "" : "absolute-position" }` }>
      <CircularProgress color="primary" />
    </div>
  );
}

export default LoaderComponent;
LoaderComponent.defaultProps = {
  normal: true,
};
LoaderComponent.propTypes = {
  normal: PropTypes.bool,
};

export function ImageSkeletonComponent( props ) {
  const { count, background } = props;
  return (
    <div className={ `image-skeleton` }>
      { [ ...Array( count ) ]?.map( ( el, index ) => (
        <Skeleton
          className="skeleton-image-element"
          key={ index }
          variant="rectangular"
          width={ 120 }
          height={ background ? 80 : 120 }
        />
      ) ) }
    </div>
  );
}

export function TableSkeleton( props ) {
  const { count } = props;
  return (
    <Box sx={ { width: "96%", margin: "0 auto" } }>
      { Array( parseInt( count ) )
        .fill()
        .map( ( val, key ) => (
          <Skeleton animation="wave" height={ 50 } key={ key } />
        ) ) }{ " " }
    </Box>
  );
}

export function CircularDashboardSkeleton( props ) {
  const { count } = props;
  return (
    <div
      style={ {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        margin: "10px 0px",
      } }
    >
      <Skeleton variant="circular" height="200px" width="200px" />
    </div>
  );
}
export function DashboardSkeleton( props ) {
  const { count } = props;
  return (
    <Box sx={ { height: "100%", margin: "0px 10px" } }>
      <Skeleton animation="wave" height={ 300 } />
    </Box>
  );
}
export const PlansSkeleton = () => {
  return (
    <Box className="plans-skeleton">
      { Array( 4 ).fill()?.map( ( el ) => ( <Skeleton className='plan-item' variant="wave" width={ 300 } height={ 500 } /> ) ) }

    </Box>
  );
};