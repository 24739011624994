//REACT UTILITIES
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//COMPONENT

//ACCOUNT
import AccountInfo from "../../pages/portal-pages/account/AccountInfo";
import AccountProfile from "../../pages/portal-pages/account/AccountProfile";
import AccountPassword from "../../pages/portal-pages/account/AccountPassword";

//SUBSCRIBER
import Users from "../../pages/portal-pages/users/Users";
import UsersDetail from "../../pages/portal-pages/users/UsersDetail";

//SUBSCRIPTIONS
import Subscriptions from "../../pages/portal-pages/subscriptions/Subscriptions";
import SubscriptionHandle from "../../pages/portal-pages/subscriptions/SubscriptionHandle";
import SubscriptionCardView from "../../pages/portal-pages/subscriptions/SubscriptionCardView";

//SESSION
import SessionsHandle from "../../pages/portal-pages/session/SessionHandle";
import DeletedSessions from "../../pages/portal-pages/session/DeletedSessions";
import Sessions from "../../pages/portal-pages/session/Sessions";

//AUTHENTICATION
import EmailSent from "../../pages/authentication-pages/authentication-messages/EmailSent";
import ResetPassword from "../../pages/authentication-pages/reset-password/ResetPassword";
import PasswordChanged from "../../pages/authentication-pages/authentication-messages/PasswordChanged";
import LinkSent from "../../pages/authentication-pages/authentication-messages/LinkSent";
import Login from "../../pages/authentication-pages/login/Login";
import ForgotPassword from "../../pages/authentication-pages/forgot-password/ForgotPassword";

//COUPONS
import Coupons from "../../pages/portal-pages/coupons/Coupons";
import CouponsDetail from "../../pages/portal-pages/coupons/CouponsDetail";
import CouponsHandle from "../../pages/portal-pages/coupons/CouponsHandle";

//IMAGES
import ImagesCategories from "../../pages/portal-pages/image-management/ImagesCategories";
import CategoryImages from "../../pages/portal-pages/image-management/CategoryImages";

//INVOICES
import Invoices from "../../pages/portal-pages/invoices/Invoices";
import InvoicesDetail from "../../pages/portal-pages/invoices/InvoicesDetail";

//DASHBOARD
import Dashboard from "../../pages/portal-pages/dashboard/Dashboard";
import LayoutWithNavbar from "../../layout/LayoutWithNavbar";

//COMMON
import NoPageFound from "../../components/response-pages/NoPageFound";
import ProtectedRoutes from "./ProtectedRoutes";
import PrivateRoutes from "./PrivateRoutes";

export function AppRouter( props ) {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={ <ProtectedRoutes shouldbeloggedIn={ true } /> }>
          <Route
            path="/"
            element={
              <LayoutWithNavbar
                login={ true }
                landing={ false }
                authentication={ false }
              />
            }
          >
            <Route index element={ <Users /> } />
            <Route path="/dashboard" element={ <Dashboard /> } />

            <Route path="/sessions" element={ <Sessions /> } />
            <Route path="/sessions/new" element={ <SessionsHandle /> } />
            <Route path="/" element={ <Navigate replace to="/sessions" /> } />
            <Route path="/subscribers" element={ <Users /> } />
            <Route path="/subscribers/detail/:id" element={ <UsersDetail /> } />
            <Route path="/coupons" element={ <Coupons /> } />
            <Route path="/coupons/new" element={ <CouponsHandle /> } />
            <Route path="/coupons/edit/:id" element={ <CouponsHandle edit /> } />
            <Route path="/coupon/detail/:id" element={ <CouponsDetail /> } />
            <Route
              path="/background-images"
              element={ <CategoryImages background /> }
            />
            <Route path="/board-images" element={ <CategoryImages /> } />
            <Route path="/invoices" element={ <Invoices /> } />
            <Route path="/invoices/detail/:id" element={ <InvoicesDetail /> } />

            <Route path="/deleted-sessions" element={ <DeletedSessions /> } />
            <Route path="/account" element={ <AccountProfile /> }>
              <Route index element={ <AccountInfo type="personal" /> } />
              <Route
                path="personal-info"
                element={ <AccountInfo type="personal" /> }
              />
              <Route path="change-password" element={ <AccountPassword /> } />
              <Route
                path="subscription-info"
                element={ <AccountInfo type="subscription" /> }
              />
            </Route>

            <Route path="/account" element={ <AccountProfile /> }></Route>
            <Route path="/subscriptions" element={ <Subscriptions /> } />

            <Route
              path="/subscriptions/new"
              element={ <SubscriptionHandle type="add" /> }
            />
            <Route
              path="/subscriptions/cards-view"
              element={ <SubscriptionCardView /> }
            />
            <Route
              path="/subscriptions/edit/:slug"
              element={ <SubscriptionHandle type="edit" /> }
            />
            <Route path="*" element={ <NoPageFound /> } />
          </Route>
        </Route>
        <Route element={ <ProtectedRoutes shouldbeloggedIn={ false } /> }>
          <Route
            path="/"
            element={
              <LayoutWithNavbar
                login={ false }
                landing={ false }
                authentication={ true }
              />
            }
          >
            <Route
              path="/reset-password/:id"
              element={
                <PrivateRoutes shouldbeloggedIn={ false }>
                  <ResetPassword />
                </PrivateRoutes>
              }
            />
            <Route
              path="/login"
              element={
                <PrivateRoutes shouldbeloggedIn={ false }>
                  <Login />
                </PrivateRoutes>
              }
            />
            <Route
              path="/password-changed"
              element={
                <PrivateRoutes shouldbeloggedIn={ false }>
                  <PasswordChanged />
                </PrivateRoutes>
              }
            />

            <Route
              path="/email-sent"
              element={
                <PrivateRoutes shouldbeloggedIn={ false }>
                  <EmailSent />
                </PrivateRoutes>
              }
            />
            <Route
              path="/reset"
              element={
                <PrivateRoutes shouldbeloggedIn={ false }>
                  <ForgotPassword />
                </PrivateRoutes>
              }
            />
            <Route
              path="/link-sent"
              element={
                <PrivateRoutes shouldbeloggedIn={ false }>
                  <LinkSent />
                </PrivateRoutes>
              }
            />
          </Route>
        </Route>
        <Route
          path="/"
          element={
            <LayoutWithNavbar
              login={ false }
              landing={ true }
              authentication={ false }
            />
          }
        >
          <Route index element={ <Login /> } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default AppRouter;
