//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";

//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
//COMPONENT
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import RowComponent from "../../../components/table-component/RowComponent";
import { TableCell } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";
import { getAllSessions } from "../../../core/apis/session";
import { groupParamsByKey, } from "../../../core/functions/useDebounce";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

export function DeletedSessions( props ) {
  //VARIABLES

  const { setAlert } = useContext( AlertContext );
  const [ searchParams ] = useSearchParams();
  const { name, page, per_page } = groupParamsByKey( searchParams ) || {};
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );

  const [ loading, setLoading ] = useState( false );

  const [ data, setData ] = useState( [] );


  const tableHeaders = [
    {
      name: "Session Name",
    },
    {
      name: "Date",
    },

    {
      name: "Facilitator",
    },
  ];
  //Functions

  const GetData = () => {
    setLoading( true );

    getAllSessions( {
      is_deleted: true,
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      name: searchParams.get( 'name' ) || "",
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setTotalPage( response.data.data?.sessions?.last_page );
        setData(
          response.data.data.sessions ? response.data.data.sessions?.data : []
        );
        setTotal( response.data.data?.sessions?.total );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };







  useEffect( () => {
    GetData();
  }, [ name, per_page, page ] );



  return (
    <>
      <TitleComponent
        title={ `Deleted Sessions` }
        classNames="page-title main-title"
      />
      <TitleComponent
        title={ `Total Sessions : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        filterable={ false }
        placeholder={ "Search by session name or facilitator" }
        search
        type="sessions"
      />
      <div className="margin-up-20">
        <TableComponent
          headers={ tableHeaders }
          loading={ loading }
          data={ data }
          actions={ false }
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent actions={ false } row={ element } key={ element?.id }>
              <TableCell>{ element.name }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element?.created_at } date />
              </TableCell>
              <TableCell>
                { element?.user?.first_name + " " + element?.user?.last_name }
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection
        total={ total }
        data={ data }
        totalPage={ totalPage }

      />
    </>
  );
}

export default DeletedSessions;
