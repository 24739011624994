//UTILITIES
import React, { useState } from "react";
import PropTypes from "prop-types";

//COMPONENT
import { DialogContent, DialogActions } from "@mui/material";
import DialogCustomTitle from "./DialogCustomTitle";
import { NoticeDialog } from "../../assets/theme/SpecialComponent";
import { PrimaryButton } from "../buttons/Buttons";

//CSS
import "./DialogComponent.scss";
export function NoticeConfirmation( props ) {
  //VARIABLES
  const { data, message, onClose, onSuccess } = props;
  const [ saveLoading, setSaveLoading ] = useState( false );


  const handleSuccess = async () => {
    let res = await onSuccess( data );
    if ( res ) {
      setSaveLoading( false )
    }

  }
  return (
    <NoticeDialog open={ true }>
      <DialogCustomTitle
        title=""
        onClose={ () => onClose() }
      />
      <DialogContent>
        <div className="delete-without-reason-prompt">
          <div>Notice</div>
          <div>{ message }</div>
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          variant="outlined"
          text="No"
          onClick={ () => {
            onClose()
          } }
        />
        <PrimaryButton
          text="Yes"
          loading={ saveLoading }
          disabled={ saveLoading }
          onClick={ () => {
            setSaveLoading( true )
            handleSuccess()
          } }
        />
      </DialogActions>
    </NoticeDialog>
  );
}
export default NoticeConfirmation;
NoticeConfirmation.defaultProps = {
  message: "Are you sure you want to delete?",
};
NoticeConfirmation.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};
