//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBarContext } from "../../../context/SideBarContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { AlertContext } from "../../../context/AlertContext";
//COMPONENT
import NoDataFound from "../../../components/response-messages/NoDataFound";
import { PlansSkeleton } from "../../../components/loader-component/LoaderComponent";
import SubscriptionsCards from "../../../components/subscriptions-cards/SubscriptionsCards";
import TitleComponent from "../../../components/title-component/TitleComponent";
//CSS

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "./Subscriptions.scss";

import SwiperCore, {
  EffectFlip,
  FreeMode,
  Navigation,
  Pagination,
} from "swiper";
import { getAllPlans } from "../../../core/apis/plan";
import { ErrorMessage } from "../../../core/variables/MessageVariables";



SwiperCore.use( [ EffectFlip, FreeMode, Navigation, Pagination ] );

function useSwiperBreakpoints() {
  let { sideBar } = useContext( SideBarContext );

  const [ breakpoints, setBreakPoints ] = useState( {
    1500: {
      slidesPerView: 3,
    },

    900: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  } );

  useEffect( () => {
    setBreakPoints( {
      1500: {
        slidesPerView: 3,
      },

      900: {
        slidesPerView: sideBar ? 2 : 3,
      },
      700: {
        slidesPerView: sideBar ? 1 : 2,
      },
      600: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    } );
  }, [ sideBar ] );

  return breakpoints;
}

export function SubscriptionCardView( props ) {
  const { setAlert } = useContext( AlertContext );
  let { sideBar } = useContext( SideBarContext );
  let navigate = useNavigate();
  const [ data, setData ] = useState( [] );
  const [ loading, setLoading ] = useState( true );
  const breakpoints = useSwiperBreakpoints();

  const key = sideBar ? "sidebar" + Math.random() : "nosidebar" + Math.random();

  const GetData = () => {
    setLoading( true );
    getAllPlans().then( ( response ) => {
      if ( response?.data?.success ) {
        setData( response.data.data ? response?.data?.data : [] );
      } else {
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };

  useEffect( () => {
    GetData();
  }, [] );

  return (
    <>
      <TitleComponent
        back
        handleBack={ () => navigate( "/subscriptions" ) }
        title={ `Subscriptions Card View` }
        classNames="page-title main-title"
      />
      <TitleComponent
        title={ `Total Subscriptions : ${ data ? data?.length : 0 }` }
        classNames="page-title mini-title"
      />

      { loading ? (
        <PlansSkeleton />
      ) : data?.length !== 0 ? (
        <Swiper
          spaceBetween={ 30 }
          freeMode={ true }
          key={ key }
          navigation={ true }
          breakpoints={ breakpoints }
        >
          { data.map( ( plan, index ) => {
            return (
              <SwiperSlide
                key={ index }
                className={ sideBar === true ? "swiper-subscription" : "" }
              >
                <SubscriptionsCards plan={ plan } sideBar={ sideBar } />
              </SwiperSlide>
            );
          } ) }
        </Swiper>
      ) : (
        <NoDataFound />
      ) }
    </>
  );
}

export default SubscriptionCardView;
