// In ProtectedRoutes.js
import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";

const ProtectedRoutes = ( { shouldbeloggedIn } ) => {
  const { session } = useContext( UserContext )
  const navigate = useNavigate()
  useEffect( () => {

    if ( shouldbeloggedIn && !session ) {
      navigate( '/login' )
    } else if ( !shouldbeloggedIn && session ) {
      navigate( '/subscribers' )
    }
  }, [ shouldbeloggedIn, session ] )

  return <Outlet />
};
export default ProtectedRoutes;
