//UTILITIES
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom/dist";
import { groupParamsByKey, objectCleaner } from "../../core/functions/useDebounce";
import { dataPerPage } from "../../core/variables/CoreVariables";
//COMPONENT
import { NextIcon, PreviousIcon } from "../../assets/svg/SVG";
import {
  FormControl,
  InputLabel,
  NativeSelect,
  Pagination,
  PaginationItem,
} from "@mui/material";

//CSS
import "./TableComponent.scss";



export function TablePaginationSection(props) {
  const {
    totalPage,
    data,
    perPageData,
  } = props;


  const [searchParams, setSearchParams] = useSearchParams();


  const handlePageChange = (e, page) => {
    let newFilters = {
      ...groupParamsByKey(searchParams),
      page: page,
    }
    setSearchParams(objectCleaner(newFilters));
  }

  const handleItemPerPageChange = (e) => {
    let newFilters = {
      ...groupParamsByKey(searchParams),
      per_page: e?.target?.value,
    };
    setSearchParams(objectCleaner(newFilters));
  }

  return (
    <>
      {data && data.length !== 0 && (
        <div className="table-pagination-section">
          <FormControl className="form-control-item">
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Item Per Page
            </InputLabel>
            <NativeSelect
              onChange={handleItemPerPageChange}
              value={searchParams.get('per_page') || dataPerPage}
              inputProps={{
                id: "uncontrolled-native",
              }}
            >
              {perPageData?.map((el, index) => (
                <option value={el} key={index}>{el}</option>
              ))}
            </NativeSelect>
          </FormControl>

          <Pagination
            shape="rounded"
            count={totalPage}
            page={+searchParams.get('page') || 1}
            perPage={searchParams.get('per_page') || dataPerPage}
            onChange={handlePageChange}
            defaultPage={1}
            color="primary"
            size="small"
            renderItem={(item) => (
              <PaginationItem
                className={item.selected ? "pagination-item-selected" : ""}
                components={{ previous: PreviousIcon, next: NextIcon }}
                {...item}
              />
            )}
          />
        </div>
      )}
    </>
  );
}
export default TablePaginationSection;
TablePaginationSection.defaultProps = {
  perPageData: [10, 20, 30],
};
TablePaginationSection.propTypes = {
  perPageData: PropTypes.array,
};
