import { api } from "./main";

export const getAllCategories = ( payload ) => {
  return api
    .get( `admin/v1/get-all-categories`, {
      params: { ...payload },
    } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const updateCategoryOrder = async ( payload ) => {
  return await api
    .put( `admin/v1/update-order/${ payload?.parent_slug }`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
