import React, { createContext, useEffect, useState } from "react";
import ResponseSnackbar from "../components/response-messages/ResponseSnackbar";

const AlertContext = createContext({
  text: null,
  error: false,
});

const AlertContextProvider = (props) => {
  const { children } = props;
  const [alert, setAlert] = useState({
    text: "",
    error: false,
  });
  useEffect(() => {
    alert.text &&
      setTimeout(() => {
        setAlert({ text: null });
      }, 3000);
  }, [alert]);

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {alert.text && (
        <ResponseSnackbar
          error={alert.error}
          message={alert.text}
          onClose={() =>
            setAlert({
              ...alert,
              text: false,
            })
          }
          text={alert.text}
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};
export { AlertContextProvider, AlertContext };
