import React, { createContext, useEffect, useState } from "react";
import { getSelfUser } from "../core/apis/user";
import { useCookies } from "react-cookie";
import { isUserLoggedIn } from "../core/apis/auth";

const UserContext = createContext( {
  user: null,
  updateUser: () => { },
} );

const UserContextProvider = ( props ) => {
  const { children } = props;
  const [ cookies, setCookie ] = useCookies( [ "session" ] );
  const [ user, setUser ] = useState( null );


  //FUNCTIONS


  const GetData = async () => {
    const response = await getSelfUser();
    if ( response.data?.success ) {
      setUser( response.data.data );
    }

  };


  useEffect( () => {
    //cookies is used to keep the user logged in if the token is still valid and he didn't logout
    isUserLoggedIn().then( ( res ) => {
      setCookie( "session", Boolean( res?.data?.data?.is_loggedIn ), { path: "/" } );
    } )
  }, [] )

  useEffect( () => {
    if ( cookies?.session ) {
      GetData();
    }

  }, [ cookies?.session ] );

  return (
    <UserContext.Provider value={ { user, updateUser: () => GetData(), session: cookies?.session } }>
      { children }
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };
