import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useContext, useEffect } from "react";

//COMPONENT
import {
  FormCheckBox,
  FormInput,
  FormPassword,
} from "../../../components/form-component/FormComponent";

import { AlertContext } from "../../../context/AlertContext";
import { PrimaryButton } from "../../../components/buttons/Buttons";

//API
import { checkSessionName } from "../../../core/apis/session";
import { nameRequiredLimited } from "../../../core/validators/form-validators";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

const HandleSchema = yup.object( {
  name: nameRequiredLimited( true, 255 ),
  has_password: yup.boolean(),
  password: yup.string().when( "has_password", {
    is: true,
    then: yup.string().required( "Field is required" ),
    otherwise: yup.string(),
  } ),
} );

export function SessionDescription( props ) {
  const { SubmitStep, activeStep, formValues } = props;
  let navigate = useNavigate();
  //INITIATE USE FORM
  const { control, handleSubmit, setValue, getValues, clearErrors } = useForm( {
    defaultValues: {
      name: formValues?.name || "",
      has_password: formValues?.has_password || false,
      password: formValues?.password || "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  const [ checkPassword, setCheckPassword ] = useState( false );
  const { setAlert } = useContext( AlertContext );
  const [ saveLoading, setSaveLoading ] = useState( false );
  //FUNCTIONS

  const handleNextStep = ( formData ) => {
    setSaveLoading( true );
    checkSessionName( { name: formData?.name } ).then( ( res ) => {
      if ( res?.data?.success ) {
        SubmitStep( formData );
      } else {
        setAlert( {
          text: res.data ? res.data.message : ErrorMessage,
          error: true,
        } );
      }
      setSaveLoading( false );
    } );
  };

  const handleChangeCheckbox = ( e ) => {
    setCheckPassword( e );
    setValue( "has_password", e, {
      shouldValidate: true,
    } );
    setValue( "password", "", {
      shouldValidate: true,
    } );
  };

  return (
    <form onSubmit={ handleSubmit( handleNextStep ) } className="session-description">

      <Controller
        render={ ( { field: { onChange, value }, fieldState: { error } } ) => (
          <FormInput
            required
            name="name"
            label="Session name"
            placeholder="Enter session name"
            value={ value }
            helperText={ error?.message }
            onChange={ ( e ) => {
              onChange( e );
            } }
          />
        ) }
        name="name"
        control={ control }
      />

      {/* //don't remove duplicate classname => there is a purpose */ }
      <div className="session-password">

        <Controller
          render={ ( { field: { onChange, value }, fieldState: { error } } ) => (
            <FormCheckBox
              name="has_password"
              label="With Password"
              value={ value }
              checked={ value }
              onChange={ ( e ) => handleChangeCheckbox( e ) }
            />
          ) }
          name="has_password"
          control={ control }
        />

        { checkPassword === true ? (
          <Controller
            rules={ { required: getValues( "has_password" ) } }
            render={ ( {
              field: { onChange, value },
              fieldState: { error },
            } ) => (
              <FormPassword
                required={ getValues( "has_password" ) }
                name="password"
                label="Choose a password for this session"
                placeholder="Enter password"
                value={ value }
                helperText={ error?.message }
                onChange={ ( e ) => {
                  onChange( e );
                  clearErrors( "password" );
                } }
              />
            ) }
            name="password"
            control={ control }
          />
        ) : (
          ""
        ) }

      </div>
      <div className="form-footer-style display-footer-right margin-up-20">
        <div className="form-footer-elements">
          { activeStep === 0 && (
            <PrimaryButton
              variant={ "outlined" }
              text="Cancel"
              onClick={ () => navigate( -1 ) }
            />
          ) }
          <PrimaryButton
            text={ "Next" }
            type={ "submit" }
            loading={ saveLoading }
            disabled={ saveLoading ? true : false }
          />
        </div>
      </div>
    </form>
  );
}
export default SessionDescription;
