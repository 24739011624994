//UTILITIES
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { adminLogout } from "../core/apis/auth";
import { AlertContext } from "../context/AlertContext";
import { SideBarContext } from "../context/SideBarContext";
//MUI
import {
  SideBarDrawer,
  DrawerHeader,
} from "../../src/assets/theme/SpecialComponent";
import List from "@material-ui/core/List";

//COMPONENT
import NoticeConfirmation from "../components/dialog-component/NoticeConfirmation";
import { SideBarItems } from "../core/variables/SideBarItems";
import CustomizedListItem from "../components/side-bar-component/CustomizedListItem";
import logo from "../assets/images/Ceci_tool_white.png";
import { BurgerSVG, LogoutIcon } from "../assets/svg/SideBarSVG";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

//CSS
import "./NavigationBar.scss";
import { UserContext } from "../context/UserContext";
import { ErrorMessage } from "../core/variables/MessageVariables";


export function NavigationBar( props ) {
  //VARIABLES
  const [ cookies, setCookies, removeCookie ] = useCookies( [ "session" ] );
  let { authentication } = props;
  const { sideBar, setSideBar } = useContext( SideBarContext );
  const { setAlert } = useContext( AlertContext );
  let { user, session } = useContext( UserContext );
  let navigate = useNavigate();

  const [ openConfirm, setOpenConfirm ] = React.useState( false );

  //FUNCTIONS
  const handleLogOutResponse = () => {

    adminLogout().then( ( res ) => {
      if ( res.data?.success ) {
        removeCookie( 'session', { path: '/' } );
        setOpenConfirm( false );
        setSideBar( false );
        navigate( "/login" );
      } else {
        setAlert( {
          text: res.data ? res.data.message : ErrorMessage,
          error: true,
        } );

        setOpenConfirm( false );
      }
      return res;
    } );


  };
  const handleSideBar = () => {
    setSideBar( !sideBar );
  };
  const handleCloseDrawer = () => {
    setSideBar( false );
  };

  const handleLogout = () => {
    setOpenConfirm( true );
  };


  return (
    <>
      <div className="navigationBar">
        <div className="navigationBar-container">
          { !sideBar && (
            <div className="logo">
              { session && !authentication && (
                <BurgerSVG
                  onClick={ handleSideBar }
                  className="burger cursor-pointer"
                />
              ) }
              <div>
                <img
                  src={ logo }
                  alt="LOGO"
                  onClick={ () =>
                    session ? navigate( "/subscribers" ) : navigate( "/login" )
                  }
                />
              </div>
            </div>
          ) }
        </div>
      </div>

      <SideBarDrawer
        sx={ {
          width: 300,
          flexShrink: 0,
        } }
        variant="persistent"
        anchor="left"
        open={ sideBar && session && !authentication }
        ModalProps={ {
          keepMounted: true,
        } }
      >
        <DrawerHeader className="drawer-header-container">
          <div className="text">
            <img
              src={ logo }
              alt="LOGO"
              onClick={ () =>
                session ? navigate( "/subscribers" ) : navigate( "/login" )
              }
            />
          </div>
          { session && (
            <BurgerSVG
              onClick={ handleSideBar }
              className="burger cursor-pointer"
            />
          ) }
        </DrawerHeader>
        <List>
          { SideBarItems.map( ( item, index ) => (
            <CustomizedListItem
              key={ item.id }
              item={ item }
              index={ index }
              length={ SideBarItems.length }
              handleCloseDrawer={ handleCloseDrawer }
            />
          ) ) }
          <ListItem>
            <ListItemButton
              sx={ { pl: 2.5 } }
              onClick={ () => {
                handleLogout();
              } }
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={ "Log out" } />
            </ListItemButton>
          </ListItem>
        </List>
      </SideBarDrawer>
      { openConfirm && (
        <NoticeConfirmation
          message="Are you sure you want to logout ?"
          onSuccess={ ( payload ) => handleLogOutResponse( payload ) }
          onClose={ () => setOpenConfirm( false ) }
        />
      ) }
    </>
  );
}
export default NavigationBar;
