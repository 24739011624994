//REACT
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Paper, Table, TableBody, TableContainer } from "@mui/material";
import palette from "../../assets/theme/color.scss";
import TableCustomHeader from "./TableHeader";

import NoDataFound from "../response-messages/NoDataFound";
import LoaderComponent, {
  TableSkeleton,
} from "../loader-component/LoaderComponent";
//DRAG FUNCTIONALITY
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
//CSS
import "./TableComponent.scss";
import { dataPerPage } from "../../core/variables/CoreVariables";

const TableComponent = (props) => {
  const {
    children,
    requestSort,
    sorted,
    orderBy,
    headers,
    actions,
    data,
    sortedBy,
    handleDragEnd,
    loading,

    handleAdd,
  } = props;

  //FUNCTIONS

  const handleSortRequest = () => {
    if (sorted) requestSort();
  };
  // Drag sensors from external library
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <>
      <Paper className="table-paper">
        <TableContainer
          className="table-container-component"
          sx={{
            "&::-webkit-scrollbar":
              data?.length !== 0 && !loading
                ? {
                  width: 4,
                  height: 5,
                }
                : { display: "none" },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: palette.lightPrimaryColor,
              borderRadius: 25,
            },
          }}
        >
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext
              items={data || []}
              strategy={verticalListSortingStrategy}
            >
              <Table
                stickyHeader
                className="initial-table"
                aria-label="sticky table"
              >
                <TableCustomHeader
                  sorted={sorted}
                  requestSort={handleSortRequest}
                  orderBy={orderBy}
                  sortedBy={sortedBy}
                  headers={headers}
                  actions={actions}
                  onAdd={handleAdd}
                />

                {!loading && (
                  <TableBody {...props}>
                    {data?.length !== 0 ? (
                      children
                    ) : (
                      <NoDataFound normal={false} />
                    )}
                  </TableBody>
                )}
              </Table>
            </SortableContext>
          </DndContext>
          {loading && <TableSkeleton count={dataPerPage} />}
        </TableContainer>
      </Paper>
    </>
  );
};

export default TableComponent;

TableComponent.defaultProps = {
  actions: true,
  loading: false,
  prefixActions: false,
};
TableComponent.propTypes = {
  prefixActions: PropTypes.bool,
  actions: PropTypes.bool,
  loading: PropTypes.bool,
  headers: PropTypes.array,
};
