//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
//COMPONENT

import TitleComponent from "../../../components/title-component/TitleComponent";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import { Card, CardContent, Skeleton, TableCell } from "@mui/material";
import {
  exportCouponUsers,
  getCouponById,
  getCouponUsers,
} from "../../../core/apis/coupon";
import TableComponent from "../../../components/table-component/TableComponent";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import { AlertContext } from "../../../context/AlertContext";
import { useSearchParams } from "react-router-dom/dist";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import RowComponent from "../../../components/table-component/RowComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

//API

export function CouponsDetail( props ) {
  //VARIABLES
  let { id } = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  let [ searchParams ] = useSearchParams();
  const { per_page, page, name, start_date, end_date } = groupParamsByKey( searchParams ) || {};
  const [ coupon, setCoupon ] = useState( null );
  const [ loading, setLoading ] = useState( false );
  const [ couponUsers, setCouponUsers ] = useState( [] );
  const [ usersLoading, setUsersLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );


  const tableHeaders = [
    {
      name: "Name",
    },
    {
      name: "Email",
    },
    {
      name: "Plan",
    },
    {
      name: "Plan Price",
    },
    {
      name: "Discount",
    },
    {
      name: "Total Invoiced",
    },

    {
      name: "Invoice Number",
    },
  ];

  //FUNCTIONS

  const GetData = () => {
    setLoading( true );
    getCouponById( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        setCoupon( res?.data?.data );
      }
      setLoading( false );
    } );
  };

  const GetCouponUsedBy = () => {
    setUsersLoading( true );
    getCouponUsers( {
      id: id,
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      search: searchParams.get( 'name' ) || "",
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) || "" },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" }
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setCouponUsers( res?.data?.data ? res?.data?.data?.data?.data : [] );
        setTotal( res?.data?.data ? res?.data?.data?.data?.total : 0 );
        setTotalPage( res?.data?.data ? res?.data?.data?.data?.last_page : 0 );
      }
      setAlert( {
        text: res?.data ? res?.data?.message : ErrorMessage,
        error: res?.data?.success ? false : true,
      } );
      setUsersLoading( false );
    } );
  };
  useEffect( () => {
    GetData();
  }, [] );

  useEffect( () => {
    GetCouponUsedBy();
  }, [ name, per_page, page, start_date, end_date, ] );



  const handleExportFunctionality = () => {
    if ( searchParams.get( 'start_date' ) && searchParams.get( 'end_date' ) ) {
      exportCouponUsers( {
        start_date: searchParams.get( 'start_date' ),
        end_date: searchParams.get( 'end_date' ),
        id: id,
      } ).then( ( response ) => {
        if ( response?.status === 200 ) {
          const url = window.URL.createObjectURL( new Blob( [ response?.data ] ) );
          let filename = `${ coupon?.code }-users.xlsx`;
          const link = document.createElement( "a" );
          link.href = url;
          link.setAttribute( "download", filename );
          // Append to html link element page
          document.body.appendChild( link );
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild( link );
        } else {
          setAlert( {
            text: "Failed to download",
            error: true,
          } );
        }
      } );
    } else {
      setAlert( {
        text: "Please select a date range",
        error: true,
      } );
    }
  };




  return (
    <>
      { !loading ? (
        <>
          <TitleComponent
            title={ coupon?.name }
            classNames="page-title main-title"
            back
            handleBack={ () => navigate( '/coupons' ) }
          />
          <Card>
            <CardContent>
              <TitleComponent
                title={ "Detail" }
                classNames="page-title sub-title"
              />
              <div className="coupon-detail-component">
                <div className="coupon-detail-row">
                  <div>Code</div>
                  <div>{ coupon?.code }</div>
                </div>
                <div className="coupon-detail-row">
                  <div>Name</div>
                  <div>{ coupon?.name }</div>
                </div>
                <div className="coupon-detail-row">
                  <div>Description</div>
                  <div>{ coupon?.description }</div>
                </div>

                <div className="coupon-detail-row">
                  <div>Discount Value</div>
                  <div>{ coupon?.value + "%" }</div>
                </div>
                <div className="coupon-detail-row">
                  <div>Created Date</div>
                  <div>
                    <DisplayDateTime data={ coupon?.created_at } date />
                  </div>
                </div>
                <div className="coupon-detail-row">
                  <div>Expiration Date</div>
                  <div>
                    <DisplayDateTime data={ coupon?.expiry_date } date />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      ) : (
        <Skeleton />
      ) }
      <div className="margin-up-20">
        <TitleComponent title={ "Used By" } classNames="page-title sub-title" />
        <TitleComponent
          title={ `Total Users ${ total ? total : 0 }` }
          classNames="page-title mini-title"
        />
        <div className="margin-up-20">
          <TableUpsideSection
            type="users"
            filterByDateRange
            rangeOn={ "Invoice Date" }
            placeholder={ "Search by name or email" }
            exportFunctionality={ total > 0 && handleExportFunctionality }


          />
          <div className="margin-up-20">
            <TableComponent
              headers={ tableHeaders }
              loading={ usersLoading }
              data={ couponUsers }
              actions={ false }
              sorted
            >
              { couponUsers?.map( ( element ) => (
                <RowComponent
                  row={ element }
                  key={ element?.id }
                  actions={ false }
                  handleRowClick={ () =>
                    navigate( `/subscribers/detail/${ element?.user_id }` )
                  }
                >
                  <TableCell>
                    { element.user_first_name + " " + element?.user_last_name }
                  </TableCell>
                  <TableCell>{ element.user_email }</TableCell>
                  <TableCell>{ element.invoice_items?.[ 0 ]?.item_name }</TableCell>
                  <TableCell>{ element.subtotal }</TableCell>
                  <TableCell>{ element.discounted_value }</TableCell>
                  <TableCell> { element.total }</TableCell>
                  <TableCell>{ element.invoice_number }</TableCell>
                </RowComponent>
              ) ) }
            </TableComponent>
          </div>
          <TablePaginationSection
            total={ total }
            data={ couponUsers }
            totalPage={ totalPage }


          />
        </div>
      </div>
    </>
  );
}

export default CouponsDetail;
