//UTILITIES
import moment from "moment";
import "moment-timezone";

export const isDateExpired = (utcDateTime) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //to get the timezone of the user<

    const utcMoment = moment.utc(utcDateTime, 'YYYY-MM-DD HH:mm:ss');
    const userMoment = utcMoment.tz(userTimezone);

    return userMoment.isBefore(moment());
}
export default isDateExpired;  