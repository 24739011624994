import { api } from "./main";

export const getDashboardSessions = ( payload ) => {
  return api
    .get( `admin/v1/dashboard/sessions`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const getDashboardSubscribers = ( payload ) => {
  return api
    .get( `admin/v1/subscribers`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const getInvoicesStatistics = ( payload ) => {
  return api
    .get( `admin/v1/dashboard/invoices-statistics`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
