import { api } from "./main";

export const getAllImages = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `admin/v1/get-all-images${ page }`, {
      params: { ...payload },
    } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => { return error } );
};

export const uploadImage = async ( payload ) => {
  return await api
    .post( `admin/v1/upload-image`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => { return error } );
};
export const deleteImage = async ( payload ) => {
  return await api
    .delete( `admin/v1/delete-image/${ payload }` )
    .then( ( res ) => res )
    .catch( ( error ) => { return error } );
};

export const updateImageOrder = async ( payload ) => {
  return await api
    .put( `admin/v1/update-image-order`, payload )
    .then( ( res ) => res )
    .catch( ( error ) => { return error } );
};
