//UTILITIES
import React from "react";
//CSS
import "./SubscriptionsCards.scss";
import palette from "../../assets/theme/color.scss";
//COMPONENT
import { Card, CardContent, Divider } from "@mui/material";
import TitleComponent from "../title-component/TitleComponent";
import { CheckCircle } from "../../assets/svg/SVG";

export function SubscriptionsCards( { plan, sideBar } ) {
  return (
    <Card className="plan-card-component">
      <CardContent className="plan-card-content">
        <div className="centered-element">
          <div>
            <TitleComponent
              title={ plan.name.toUpperCase() }
              classNames="form-title plan-title"
            />
          </div>
          <div className="trial-section">
            { plan.trial_duration +
              " " +
              plan.trial_duration_type?.charAt( 0 ).toUpperCase() +
              plan.trial_duration_type.slice( 1 ) }{ " " }
            FREE TRIAL
          </div>
          <div className="description-section">{ plan.description }</div>
        </div>
        <hr className="plan-component-seperator" />
        <div className={ `plan-package-price ` }>
          { plan.plan_price.map( ( price, index ) => (
            <>
              <div
                key={ index }
                className={ `price-element ${ sideBar ? "sideBarOpen" : "SideBarClose"
                  }` }
              >
                <div className="price-currency">
                  { price.price }
                  { price.currency.symbol }
                </div>
                <div className="duration">
                  { `/${ price.package_duration } ${ price.package_duration_type }` }
                </div>
              </div>
              { plan?.plan_price.length !== 1 &&
                index !== plan?.plan_price?.length - 1 && (
                  <div className="plan-package-seperator ">
                    <Divider
                      orientation="vertical"
                      sx={ {
                        background: palette.greyColor,
                      } }
                      style={ {
                        height: "100%",
                        width: "1px",
                      } }
                    />
                  </div>
                ) }
            </>
          ) ) }
        </div>
        <hr className="plan-component-seperator" />
        <div className="plan-component-items">
          { plan?.items?.map( ( item, index ) => (
            <div key={ index } className="plan-item">
              <CheckCircle />
              <div>
                { `${ item.pivot.is_unlimited === 1 ? "Unlimited" : item.pivot.value } ${ item?.name }` }
              </div>
            </div>
          ) )
          }
        </div>
      </CardContent>
    </Card>

  );
}

export default SubscriptionsCards;
