//UTILTIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
//COMPONENT
import { PrimaryButton } from "../../../components/buttons/Buttons";
import {
  FormCalendarInput,
  FormInput,
  FormNumberInput,
} from "../../../components/form-component/FormComponent";

import TitleComponent from "../../../components/title-component/TitleComponent";
import LoaderComponent from "../../../components/loader-component/LoaderComponent";
//API
import {
  createCoupon,
  editCoupon,
  getCouponById,
} from "../../../core/apis/coupon";

//CSS
import "./Coupons.scss";
import { AlertContext } from "../../../context/AlertContext";
import { nameRequiredLimited } from "../../../core/validators/form-validators";
import { ErrorMessage } from "../../../core/variables/MessageVariables";

const HandleSchema = yup.object( {
  name: nameRequiredLimited( true, 255 ),
  code: yup
    .string()
    .test( "spaces", "Field cannot include spaces", ( val ) => !/\s/g.test( val ) )
    .test( "len", "Field is required", ( val ) => val.length > 0 )
    .test(
      "len",
      "Field should be up to 100 characters",
      ( val ) => val.length < 100
    )
    .required( "Field is required" ),
  description: nameRequiredLimited( false, 50 ),
  value: yup
    .number()
    .nullable()
    .required( "Field is required" )
    .min( 1, "Field should be greater than 1" )
    .max( 99, "Field should be less than 100" ),
  expiry_date: yup.string().required( "Field is required" ),
} );

export function CouponsHandle( props ) {
  //VARIABLES
  const { edit } = props;
  let { id } = useParams();
  const { setAlert } = useContext( AlertContext );
  let navigate = useNavigate();
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const today = new Date();
  let tomorrow = new Date( today );
  tomorrow.setDate( today.getDate() + 1 );
  const dateAfter3Years = today?.setFullYear( today?.getFullYear() + 3 );
  //INITIATE USE FORM
  const { control, handleSubmit, reset, getValues, values } = useForm( {
    defaultValues: {
      name: "",
      code: "",
      description: "",
      value: null,
      expiry_date: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    if ( !edit ) {
      createCoupon( {
        ...formData,
        expiry_date: moment( formData?.expiry_date ).format( "YYYY-MM-DD" ),
      } ).then( ( response ) => {
        if ( response.data?.success ) {
          navigate( "/coupons" );
        }
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
        setSaveLoading( false );
      } );
    } else {
      editCoupon( {
        id: id,
        ...formData,
        expiry_date: moment( formData?.expiry_date ).format( "YYYY-MM-DD" ),
      } ).then( ( response ) => {
        if ( response.data?.success ) {
          navigate( "/coupons" );
        }
        setAlert( {
          text: response.data ? response.data.message : ErrorMessage,
          error: response.data?.success ? false : true,
        } );
        setSaveLoading( false );
      } );
    }
  };

  const GetData = () => {
    setLoading( true );
    getCouponById( id ).then( ( response ) => {
      if ( response.data?.success ) {
        let response_data = response.data.data;
        reset( {
          code_display: response_data?.code,
          code: response_data?.code,
          subscription_count: response_data?.subscription_count,
          name: response_data?.name,
          description: response_data?.description,

          value: response_data?.value,

          expiry_date: response_data?.expiry_date,
        } );
      }

      setLoading( false );
    } );
  };

  useEffect( () => {
    if ( edit ) {
      GetData();
    }
  }, [] );

  return (
    <>
      <TitleComponent
        title={ edit ? getValues( "code_display" ) : "New coupon" }
        classNames="page-title main-title"
      />

      { !loading ? (
        <form onSubmit={ handleSubmit( handleSubmitForm ) }>
          <div className="form-double-inputs-children">
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  required
                  label="Code"
                  name="code"
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  placeholder={ "Enter code" }
                  helperText={ error?.message }
                  disabled={ edit ? true : false }
                />
              ) }
              name="code"
              control={ control }
            />
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  required
                  label="Name"
                  name="name"
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  placeholder={ "Enter name" }
                  helperText={ error?.message }
                  disabled={ edit ? true : false }
                />
              ) }
              name="name"
              control={ control }
            />{ " " }
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  label="Description"
                  name="description"
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  placeholder={ "Enter description" }
                  helperText={ error?.message }
                  disabled={ edit ? true : false }
                />
              ) }
              name="description"
              control={ control }
            />
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormNumberInput
                  required
                  percent
                  label="Discount value"
                  name="value"
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value === "" ? null : value );
                  } }
                  placeholder={ "Enter value" }
                  helperText={ error?.message }
                  disabled={ edit ? true : false }
                />
              ) }
              name="value"
              control={ control }
            />
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormCalendarInput
                  required
                  tooltip={ "Date can be set for maximum 3 years from today" }
                  label="Expiration date"
                  name="expiry_date"
                  value={ value }
                  minDate={ tomorrow }
                  maxDate={ dateAfter3Years }
                  onChange={ ( value ) => {
                    onChange( value );
                  } }
                  placeholder={ "Enter date" }
                  helperText={ error?.message }
                />
              ) }
              name="expiry_date"
              control={ control }
            />
          </div>

          <div className="form-footer-style display-footer-right">
            <div className="form-footer-elements">
              <PrimaryButton
                variant="outlined"
                text="Cancel"
                onClick={ () => navigate( -1 ) }
              />
              <PrimaryButton
                text={ !edit ? "Add" : "Edit" }
                type="submit"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </div>
        </form>
      ) : (
        <LoaderComponent />
      ) }
    </>
  );
}

export default CouponsHandle;
